export const title = "Reservar un auto";
export const brandLabel = "Selecciona una marca";
export const modelLabel = "Selecciona un modelo";
export const withdrawLabel = "Sucursal de retiro y devolución";
export const deliveryLabel = "Regresar en lugar de retiro";
export const datesLabel = "Fecha de retiro y devolución";
export const subsidiaryMessage = "Selecciona una sucursal";
export const datesMessage = "Selecciona fechas de retiro y devolución";
export const subsidiaryPlaceholder = "Sucursal";
export const withdrawPlaceholder = "Retiro";
export const deliveryPlaceholder = "Devolución";
export const datesPlaceholder: [string, string] = [
  "Fecha Retiro",
  "Fecha Devolución",
];
export const allOption = "TODOS";
export const search = "BUSCAR";

export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
