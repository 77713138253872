import React, { ReactElement } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import styles from "./carModelDetailsTable.module.scss";
import { CarModel } from "../../../services/outwardTypes/carModels";
import {
  CarDoor,
  AirConditioning,
  Transmission,
  Capacity,
} from "../../../assets/icons";

type DescriptionTableElement = {
  name: string;
  icon: string;
  value: string | null;
  key: string;
};

const CarModelDetailsTable = (carModel: CarModel): ReactElement<CarModel> => {
  let component = <div />;

  // eslint-disable-next-line react/destructuring-assignment
  if (carModel.modelDescription) {
    const {
      modelDescription: { doors, airConditioning, transmission, capacity },
    } = carModel;

    const columns: ColumnsType<DescriptionTableElement> = [
      {
        render: function renderIcon(model: DescriptionTableElement) {
          const { icon } = model;
          return <img src={icon} alt="carSpecs" className={styles.icon} />;
        },
      },
      {
        dataIndex: "name",
      },
      {
        dataIndex: "value",
        align: "left",
      },
    ];

    const data: DescriptionTableElement[] = [
      {
        key: "1",
        name: "Puertas",
        icon: CarDoor,
        value: doors,
      },
      {
        key: "2",
        name: "Aire Acondicionado",
        icon: AirConditioning,
        value: airConditioning,
      },
      {
        key: "3",
        name: "Transmisión",
        icon: Transmission,
        value: transmission,
      },
      {
        key: "4",
        name: "Capacidad",
        icon: Capacity,
        value: capacity,
      },
    ];

    component = (
      <Table
        showHeader={false}
        columns={columns}
        dataSource={data}
        bordered={false}
        pagination={false}
        size="small"
        rowClassName={styles.row}
      />
    );
  }

  return component;
};

export default CarModelDetailsTable;
