import React from "react";
import { Modal } from "antd";

export const infoModal = (
  title: string,
  content: string,
  onOk?: () => void
): void => {
  Modal.info({
    title,
    content,
    onOk,
    centered: true,
  });
};

export const successModal = (
  title: string,
  content: string,
  onOk?: () => void
): void => {
  Modal.success({
    title,
    content,
    centered: true,
    onOk,
  });
};

export const errorModal = (
  title: string,
  content: string,
  code: string,
  onOk?: () => void
): void => {
  const contentMessage = (
    <div>
      <h3>{content}</h3>
      <p className="errorCode">({code})</p>
    </div>
  );
  Modal.error({
    title,
    content: contentMessage,
    centered: true,
    onOk,
  });
};

export const warningModal = (
  title: string,
  content: string,
  onOk?: () => void
): void => {
  Modal.warning({
    title,
    content,
    centered: true,
    onOk,
  });
};
