import { createSlice } from "@reduxjs/toolkit";
import { BrandsState } from "../types/brandsTypes";
import { baseRequestStatusReducers } from "./base";

const initialState: BrandsState = {
  brands: {},
  getBrandsStatus: {
    loading: false,
    success: false,
    error: false,
  },
  brandsFamilies: {},
  getBrandsFamiliesStatus: {
    loading: false,
    success: false,
    error: false,
  },
  brandsCarModels: {},
  getBrandsCarModelsStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const brandsSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    ...baseRequestStatusReducers<BrandsState>("getBrands", "brands"),
    ...baseRequestStatusReducers<BrandsState>(
      "getBrandsFamilies",
      "brandsFamilies"
    ),
    ...baseRequestStatusReducers<BrandsState>(
      "getBrandsCarModels",
      "brandsCarModels"
    ),
  },
});

export const brandsReducer = brandsSlice.reducer;
export const brandsActions = brandsSlice.actions;
