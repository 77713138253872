import React, { ReactElement } from "react";
import { Layout } from "antd";
import Navbar from "./Navbar";
import Footer from "./Footer";
import SocialMedia from "./SocialMedia";
import "antd/dist/antd.css";
import "./MainLayout.scss";

interface Props {
  children: JSX.Element;
}

const MainLayout = ({ children }: Props): ReactElement => {
  const { Header, Content, Footer: FooterLayout } = Layout;
  return (
    <Layout className="layoutContainer">
      <Header
        className="layoutHeader"
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <Navbar />
      </Header>
      <Content className="layoutContent">
        <SocialMedia />
        {children}
      </Content>
      <FooterLayout className="layoutFooter" style={{ textAlign: "center" }}>
        <Footer />
      </FooterLayout>
    </Layout>
  );
};

export default MainLayout;
