import { Form, FormInstance, Select } from "antd";
import React, { ReactElement } from "react";
import { Brand, Brands } from "../../../../services/outwardTypes/brands";

interface Props {
  brands: Brands;
  allOption: string;
  form: FormInstance;
  setSelectedBrand: (value: string) => void;
}

const BrandFormItem = (props: Props): ReactElement => {
  const { brands, allOption, form, setSelectedBrand } = props;
  const { Option } = Select;
  return (
    <Form.Item name="brand">
      <Select
        onChange={(value: string): void => {
          form.setFieldsValue({ family: -1 });
          return setSelectedBrand(value);
        }}
      >
        <Option value="-1">{allOption}</Option>
        {Object.keys(brands).map((key: string): ReactElement => {
          const brand: Brand = brands[key];
          return (
            <Option value={key} key={key}>
              {brand.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default BrandFormItem;
