export const successTitle = "RESERVA EXITOSA";
export const failureTitle = "RESERVA FALLIDA";
export const successMessage =
  "Tu reserva ha sido pagada, revisa tu correo para más información";
export const failureMessage =
  "Ocurrió un error al procesar tu pago, inténtalo más tarde";
export const reservationLabel = "RESERVA";
export const transactionLabel = "TRANSACCIÓN";
export const paymentLabel = "MEDIO DE PAGO";
export const cardLabel = "NÚMERO DE TARJETA";
export const amountLabel = "MONTO";
export const homeButton = "VOLVER AL INICIO";
export const tryAgainButton = "VOLVER A INTENTARLO";
