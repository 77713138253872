import { AxiosResponse } from "axios";
import { axiosBaseInstance } from "./base";
import * as mappers from "./inwardOutwardMappers/brands";
import * as inwardTypes from "./inwardTypes/brands";
import * as outwardTypes from "./outwardTypes/brands";

export const getBrands = async (): Promise<outwardTypes.Brands> => {
  return axiosBaseInstance({
    headers: { "Content-Type": "application/json" },
    method: "get",
    url: `/brands`,
  })
    .then((response: AxiosResponse<inwardTypes.Brands>) => {
      return mappers.brandsMapper(response.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const getBrandsFamilies =
  async (): Promise<outwardTypes.BrandsFamilies> => {
    return axiosBaseInstance({
      headers: { "Content-Type": "application/json" },
      method: "get",
      url: `/brands_families`,
    })
      .then((response: AxiosResponse<inwardTypes.BrandsFamilies>) => {
        return mappers.brandsFamiliesMapper(response?.data);
      })
      .catch((error: Error) => {
        throw error;
      });
  };

export const getBrandsCarModels =
  async (): Promise<outwardTypes.BrandsCarModels> => {
    return axiosBaseInstance({
      headers: { "Content-Type": "application/json" },
      method: "get",
      url: `/brands_models`,
    })
      .then((response: AxiosResponse<inwardTypes.BrandsCarModels>) => {
        return mappers.brandsCarModelsMapper(response?.data);
      })
      .catch((error: Error) => {
        throw error;
      });
  };
