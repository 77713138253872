import { Subsidiaries } from "../../services/outwardTypes/subsidiaries";

const subsidiaryName = (
  subisidaries: Subsidiaries,
  id: number | null
): string => {
  const subsidiary = subisidaries.find((sub) => {
    return sub.id === id;
  });
  return subsidiary?.address || "";
};
export default subsidiaryName;
