const dateFormat = (date: Date | null | undefined): string => {
  if (date) {
    const dateObject = new Date(date);
    return `${dateObject.getDate()}-${
      dateObject.getMonth() + 1
    }-${dateObject.getFullYear()}`;
  }
  return "";
};
export default dateFormat;
