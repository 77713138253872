import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  Car,
  PersonalInformation,
  SearchResults,
} from "../../services/outwardTypes/rent";
import { RentState, RentActions } from "../types/rentTypes";
import { Action, baseRequestStatusReducers } from "./base";

const initialState: RentState = {
  holidays: [],
  getHolidaysStatus: {
    loading: false,
    success: false,
    error: false,
  },
  availableCars: [],
  datesInfo: null,
  subsidiariesInfo: null,
  getSearchResultsStatus: {
    loading: false,
    success: false,
    error: false,
  },
  getReservationResultStatus: {
    loading: false,
    success: false,
    error: false,
  },
  transaction: null,
  postTransactionStatus: {
    loading: false,
    success: false,
    error: false,
  },
  currentStepIndex: 0,
  carSelection: null,
  personalInformation: null,
  pickUp: null,
  dropOff: null,
  reservationResult: null,
  rentUrl: "/",
};

const successGetSearchResults = (
  state: Draft<RentState>,
  action: PayloadAction<{ [key: string]: unknown }>
) => {
  const payload = action.payload as unknown as SearchResults;
  return {
    ...state,
    availableCars: payload.availableCars,
    datesInfo: payload.datesInfo,
    getSearchResultsStatus: {
      loading: false,
      success: true,
      error: false,
    },
  };
};

const rentSlice = createSlice({
  name: "rent",
  initialState,
  reducers: {
    ...baseRequestStatusReducers<RentState>("getHolidays", "holidays"),
    ...baseRequestStatusReducers<RentState>(
      "getSearchResults",
      null,
      successGetSearchResults
    ),
    ...baseRequestStatusReducers<RentState>("postTransaction", "transaction"),
    ...baseRequestStatusReducers<RentState>(
      "getReservationResult",
      "reservationResult"
    ),
    setCurrentStepIndex: (state: RentState, action: Action): RentState => {
      return { ...state, currentStepIndex: action.payload as number };
    },
    setCarSelection: (state: RentState, action: Action): RentState => {
      return { ...state, carSelection: action.payload as Car };
    },
    setPickUp: (state: RentState, action: Action): RentState => {
      return { ...state, pickUp: action.payload as number };
    },
    setDropOff: (state: RentState, action: Action): RentState => {
      return { ...state, dropOff: action.payload as number };
    },
    setPersonalInformation: (state: RentState, action: Action): RentState => {
      return {
        ...state,
        personalInformation: action.payload as PersonalInformation,
      };
    },
    setRentUrl: (state: RentState, action: Action): RentState => {
      return {
        ...state,
        rentUrl: action.payload as string,
      };
    },
    resetRentState: (): RentState => {
      return initialState;
    },
  },
});

export const rentReducer = rentSlice.reducer;
export const rentActions = rentSlice.actions as RentActions;
