import { createSlice } from "@reduxjs/toolkit";
import { CarModelsState } from "../types/carModelsTypes";
import { baseRequestStatusReducers } from "./base";

const initialState: CarModelsState = {
  carModel: null,
  getCarModelStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const carModelsSlice = createSlice({
  name: "carModels",
  initialState,
  reducers: {
    ...baseRequestStatusReducers<CarModelsState>("getCarModel", "carModel"),
  },
});

export const carModelsReducer = carModelsSlice.reducer;
export const carModelsActions = carModelsSlice.actions;
