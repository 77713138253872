import React, { ReactElement } from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
import { Logo } from "assets/images";
import { CookiesFile, PrivacyFile } from "assets/files";
import styles from "./Footer.module.scss";

const Footer = (): ReactElement => {
  return (
    <Row className={styles.FooterContainer}>
      <Col md={12} sm={24} className={styles.Corporate}>
        <Row className={styles.FullSize} justify="space-between" align="top">
          <Col className={styles.FooterPadder}>
            <Row>
              <a
                href={CookiesFile as string}
                className={styles.FooterLink}
                target="_blank"
                rel="noreferrer"
              >
                Política de Cookies
              </a>
            </Row>
            <Row>
              <a
                href={PrivacyFile as string}
                className={styles.FooterLink}
                target="_blank"
                rel="noreferrer"
              >
                Privacidad de datos
              </a>
            </Row>
          </Col>
          <Col className={styles.FooterPadder}>
            <img src={Logo} alt="logo" width="160px" />
          </Col>
        </Row>
      </Col>
      <Col md={12} sm={0} className={styles.Brands}>
        <Row className={styles.FullSize} justify="start" align="top">
          <Col className={styles.FooterPadder}>
            <Row align="top" justify="start" gutter={[24, 16]}>
              <Col>
                <Row>
                  <a
                    href="https://www.ditec.cl/marcas/volvo/"
                    className={styles.BrandLink}
                  >
                    Volvo
                  </a>
                </Row>
              </Col>
              <Col>
                <Row>
                  <a
                    href="https://www.ditec.cl/marcas/jaguar/"
                    className={styles.BrandLink}
                  >
                    Jaguar
                  </a>
                </Row>
              </Col>
              <Col>
                <Row>
                  <a
                    href="https://www.ditec.cl/marcas/land-rover/"
                    className={styles.BrandLink}
                  >
                    Land Rover
                  </a>
                </Row>
              </Col>
              <Col>
                <Row>
                  <a
                    href="https://www.ditec.cl/marcas/porsche/"
                    className={styles.BrandLink}
                  >
                    Porsche
                  </a>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Footer;
