import React, { ReactElement, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RentSteps from "../../components/rentComponents/Steps";
import RentCarSearch from "../../components/rentComponents/Step1";
import RentPersonalInfo from "../../components/rentComponents/Step2";
import RentCheckout from "../../components/rentComponents/Step3";
import styles from "./rent.module.scss";
import { AppDispatch, RootState } from "../../store";
import * as rentInteractors from "../../store/interactors/rentInteractors";
import { RentState } from "../../store/types/rentTypes";

interface StateProps {
  rentState: RentState;
}

interface DispatchProps {
  setCurrentStepIndexInteractor: typeof rentInteractors.setCurrentStepIndexInteractor;
}

interface Props extends StateProps, DispatchProps {}

const Rent = (props: Props): ReactElement => {
  const { rentState, setCurrentStepIndexInteractor } = props;
  const { currentStepIndex } = rentState;
  const onStepChange = (current: number) => {
    setCurrentStepIndexInteractor(current);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const rentSteps = [
    <RentCarSearch key="search" />,
    <RentPersonalInfo key="personalInfo" />,
    <RentCheckout key="checkout" />,
  ];

  return (
    <div className={styles.rent}>
      <RentSteps
        currentStepIndex={currentStepIndex}
        onStepChange={onStepChange}
      />
      {rentSteps[currentStepIndex]}
    </div>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    rentState: state.rent,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...rentInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rent);
