import React, { ReactElement, useEffect } from "react";
import { Col, Form, Input, Radio, Row, Tooltip } from "antd";
import rutFormat from "utils/formats/rutFormat";
import { WarningOutlined } from "@ant-design/icons";
import Button from "../../../generalUI/Button";
import { personalInfoStep as texts } from "../../../../assets/texts/rent";
import emailRegExp from "../../../../utils/validations/emailRegExp";
import rutFormValidator from "../../../../utils/validations/rutFormValidator";
import cellPhoneRegExp from "../../../../utils/validations/cellPhoneRegExp";
import { PersonalInformation } from "../../../../services/outwardTypes/rent";

interface Props {
  columnsSpan: number;
  labelProportion: "short" | "default";
  nextStep: (info: PersonalInformation) => void;
  personalInformation: PersonalInformation | null;
}

const PersonalInfoForm = (props: Props): ReactElement => {
  const { columnsSpan, labelProportion, nextStep, personalInformation } = props;
  const spansProportion =
    labelProportion === "short"
      ? { labelCol: 5, wrapperCol: 16 }
      : { labelCol: 7, wrapperCol: 15 };
  const buttonOffset = labelProportion === "short" ? 2 : 1;
  const [form] = Form.useForm();
  useEffect(() => {
    if (personalInformation) {
      form.setFieldsValue(personalInformation);
    }
  }, [personalInformation]);

  const rutWarning = (
    <Tooltip title={texts.rutWarning}>
      <WarningOutlined />
    </Tooltip>
  );
  return (
    <Form
      form={form}
      requiredMark={false}
      labelCol={{ span: spansProportion.labelCol }}
      wrapperCol={{ span: spansProportion.wrapperCol }}
      onFinish={nextStep}
    >
      <Row>
        <Col span={columnsSpan}>
          <Form.Item
            label={texts.nameLabel}
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder={texts.namePlaceholder} />
          </Form.Item>
        </Col>
        <Col span={columnsSpan}>
          <Form.Item
            label={texts.lastNameLabel}
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input placeholder={texts.lastNamePlaceholder} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={columnsSpan}>
          <Form.Item
            label={texts.rutLabel}
            name="rut"
            rules={[{ required: true }, { validator: rutFormValidator }]}
            normalize={rutFormat}
          >
            <Input placeholder={texts.rutPlaceholder} suffix={rutWarning} />
          </Form.Item>
        </Col>
        <Col span={columnsSpan}>
          <Form.Item
            label={texts.emailLabel}
            name="email"
            rules={[
              { required: true },
              {
                message: texts.invalidEmail,
                pattern: emailRegExp(),
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input placeholder={texts.emailPlaceholder} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={columnsSpan}>
          <Form.Item
            label={texts.cellPhoneLabel}
            name="cellPhoneNumber"
            rules={[
              { required: true },
              {
                message: texts.invalidCellphone,
                pattern: cellPhoneRegExp(),
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input prefix="+56" placeholder={texts.cellPhonePlaceholder} />
          </Form.Item>
        </Col>
        <Col span={columnsSpan}>
          <Form.Item label={texts.phoneLabel} name="phoneNumber">
            <Input placeholder={texts.phonePlaceholder} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={columnsSpan}>
          <Form.Item
            label={texts.contactMethodLabel}
            name="contactMethod"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value="phone">{texts.contactPhoneOption}</Radio>
              <Radio value="email">{texts.contactEmailOption}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Form.Item>
            <Button htmlType="submit" color="goldButton">
              {texts.nextStepButton}
            </Button>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: buttonOffset }} />
      </Row>
    </Form>
  );
};

export default PersonalInfoForm;
