const redirectToWebpay = (url: string, token: string): void => {
  const form = document.createElement("form");
  form.method = "post";
  form.action = url;
  const hiddenField = document.createElement("input");
  hiddenField.type = "hidden";
  hiddenField.name = "token_ws";
  hiddenField.value = token;
  form.appendChild(hiddenField);
  document.body.appendChild(form);
  form.submit();
};
export default redirectToWebpay;
