import React, { ReactElement, useEffect } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./subsidiaries.module.scss";
import { AppDispatch, RootState } from "../../store";
import SubsidiaryCard from "../../components/subsidiaryComponents/SubsidiaryCard";
import * as subsidiariesInteractors from "../../store/interactors/subsidiariesInteractor";
import { SubsidiariesState } from "../../store/types/subsidiariesTypes";
import { title } from "../../assets/texts/subsidiaries";
import Container from "../../components/generalUI/Container";
import Title from "../../components/generalUI/Title";

interface StateProps {
  subsidiariesState: SubsidiariesState;
}

interface DispatchProps {
  getSubsidiariesInteractor: typeof subsidiariesInteractors.getSubsidiariesInteractor;
}

interface Props extends StateProps, DispatchProps {}

const Subsidiaries = (props: Props): ReactElement => {
  const { subsidiariesState, getSubsidiariesInteractor } = props;
  const subsidiaries = subsidiariesState;

  useEffect(() => {
    getSubsidiariesInteractor();
  }, [getSubsidiariesInteractor]);

  return (
    <Container>
      <Title centered>{title}</Title>
      <Row
        justify="center"
        align="top"
        className={styles.subsidiariesContainer}
        gutter={[32, 32]}
      >
        {subsidiaries.subsidiaries.map((value) => (
          <Col
            key={value.id}
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
            xxl={{ span: 6 }}
            className={styles.card}
          >
            <SubsidiaryCard subsidiary={value} />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    subsidiariesState: state.subsidiaries,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...subsidiariesInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subsidiaries);
