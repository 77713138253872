import { createSlice } from "@reduxjs/toolkit";
import { SubsidiariesState } from "../types/subsidiariesTypes";
import { baseRequestStatusReducers } from "./base";

const initialState: SubsidiariesState = {
  subsidiaries: [],
  getSubsidiariesStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const subsidiariesSlice = createSlice({
  name: "subsidiaries",
  initialState,
  reducers: {
    ...baseRequestStatusReducers<SubsidiariesState>(
      "getSubsidiaries",
      "subsidiaries"
    ),
  },
});

export const subsidiariesReducer = subsidiariesSlice.reducer;
export const subsidiariesActions = subsidiariesSlice.actions;
