import React, { ReactElement } from "react";
import { Card, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import Price from "components/sharedComponents/Price";
import styles from "./fleetCarModelCard.module.scss";
import * as texts from "../../../assets/texts/carModels";
import { FleetCarModel } from "../../../services/outwardTypes/brands";
import { Logo } from "../../../assets/images";

interface Props {
  model: FleetCarModel;
  brandName: string | null;
}

const FleetCarModelCard = (props: Props): ReactElement => {
  const { model, brandName } = props;
  const history = useHistory();
  const goToModelView = (): void => history.push(`/car-model/${model.id}`);

  return (
    <Card
      hoverable
      className={styles.modelsContent}
      bodyStyle={{ padding: "0%" }}
      onClick={goToModelView}
    >
      <Row className={styles.imageContainer}>
        <Col>
          {model.mainImageUrl ? (
            <img
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              src={model.mainImageUrl}
              alt="model"
              className={styles.image}
            />
          ) : (
            <img src={Logo} alt="brand" className={styles.image} />
          )}
        </Col>
      </Row>
      {model.id && (
        <div className={styles.modelsInfo}>
          <Row className={styles.modelsText} gutter={[20, 20]}>
            <Col className={styles.brand}>{brandName}</Col>
            <Col className={styles.model}>{model.name}</Col>
          </Row>
          {model.priceInfo?.price && (
            <Price
              hasDiscount={model.priceInfo.hasDiscount}
              priceBeforeDiscountText={texts.priceText}
              priceBeforeDiscount={model.priceInfo.priceBeforeDiscount}
              priceText={texts.priceText}
              price={model.priceInfo.price}
            />
          )}
        </div>
      )}
    </Card>
  );
};

export default FleetCarModelCard;
