import React, { ReactElement } from "react";
import { Steps } from "antd";
import { steps } from "../../../assets/texts/rent";
import useMaxBreakpoint from "../../../utils/breakpoints/useMaxBreakpoint";
import { Breakpoints } from "../../../utils/breakpoints/breakpoints";
import "./steps.scss";

const { Step } = Steps;
const disabling = true;

interface Props {
  currentStepIndex: number;
  onStepChange: (current: number) => void;
}

const RentSteps = (props: Props): ReactElement => {
  const { currentStepIndex, onStepChange } = props;
  const maxBreakpoint = useMaxBreakpoint();

  return (
    <Steps
      className="rentSteps"
      current={currentStepIndex}
      size={maxBreakpoint >= Breakpoints.MD ? "default" : "small"}
      onChange={onStepChange}
      type="navigation"
      style={{ top: 80 }}
    >
      {steps.map((step, index) => (
        <Step
          key={step.title}
          title={step.title}
          disabled={disabling && index > currentStepIndex}
          description={maxBreakpoint >= Breakpoints.MD && step.description}
        />
      ))}
    </Steps>
  );
};

export default RentSteps;
