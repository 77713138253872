import {
  CaseReducer,
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { HomeState } from "../types/homeTypes";
import { baseRequestStatusReducers } from "./base";

const initialState: HomeState = {
  promotions: [],
  getPromotionsStatus: {
    loading: false,
    success: false,
    error: false,
  },
  postNewsletterStatus: {
    loading: false,
    success: false,
    error: false,
  },
  announcement: null,
  getAnnouncementStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const successPostNewsletter: CaseReducer<
  HomeState,
  PayloadAction<{ [key: string]: unknown }>
> = (state: Draft<HomeState>) => {
  return {
    ...state,
    postNewsletterStatus: { loading: false, success: true, error: false },
  };
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    ...baseRequestStatusReducers<HomeState>("getPromotions", "promotions"),
    ...baseRequestStatusReducers<HomeState>(
      "postNewsletter",
      null,
      successPostNewsletter
    ),
    ...baseRequestStatusReducers<HomeState>("getAnnouncement", "announcement"),
  },
});

export const homeReducer = homeSlice.reducer;
export const homeActions = homeSlice.actions;
