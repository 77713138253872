import * as carModelService from "../../services/carModelsService";
import { carModelsActions } from "../slices/carModelsSlice";
import { buildInteractorDirectActionNoParams, buildInteractor } from "./base";

export const getCarModelInteractor = buildInteractor(
  carModelsActions.loadingGetCarModel,
  carModelsActions.successGetCarModel,
  carModelsActions.errorGetCarModel,
  carModelService.getCarModel
);

export const resetGerCarModelStatusInteractor =
  buildInteractorDirectActionNoParams(carModelsActions.resetGetCarModels);
