import React, { ReactElement } from "react";
import { Card, Col, Row } from "antd";
import {
  PhoneOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import styles from "./subsidiaryCard.module.scss";
import { Subsidiary } from "../../../services/outwardTypes/subsidiaries";

interface Props {
  subsidiary: Subsidiary;
}

const SubsidiaryCard = (props: Props): ReactElement => {
  const { subsidiary } = props;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: ["places"],
  });

  const mapContainerStyle = {
    height: "200px",
  };
  const cardBodyStyle = {
    padding: "0%",
  };

  const center = {
    lat: Number(subsidiary.latitude),
    lng: Number(subsidiary.longitude),
  };

  return (
    <Card
      hoverable
      className={styles.subsidiariesContent}
      bodyStyle={cardBodyStyle}
    >
      {isLoaded && (
        <div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={center}
          >
            <Marker position={center} />
          </GoogleMap>
        </div>
      )}
      {subsidiary.name && (
        <Row>
          <Col className={styles.subsidiariesName}>{subsidiary.name}</Col>
        </Row>
      )}
      {subsidiary.address && (
        <Row className={styles.subsidiariesInfo}>
          <EnvironmentOutlined className={styles.subsidiariesIcon} />
          <Col className={styles.subsidiariesText}>{subsidiary.address}</Col>
        </Row>
      )}
      {subsidiary.email && (
        <Row className={styles.subsidiariesInfo}>
          <MailOutlined className={styles.subsidiariesIcon} />
          <Col className={styles.subsidiariesText}>{subsidiary.email}</Col>
        </Row>
      )}
      {subsidiary.phone && (
        <Row className={styles.subsidiariesInfo}>
          <PhoneOutlined className={styles.subsidiariesIcon} />
          <Col className={styles.subsidiariesText}>{subsidiary.phone}</Col>
        </Row>
      )}
      {subsidiary.attentionSchedule && (
        <Row className={styles.subsidiariesInfo}>
          <CalendarOutlined className={styles.subsidiariesIcon} />
          <Col className={styles.subsidiariesText}>
            {subsidiary.attentionSchedule}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default SubsidiaryCard;
