import React, { ReactElement } from "react";
import styles from "./container.module.scss";
import useMaxBreakpoint from "../../../utils/breakpoints/useMaxBreakpoint";
import { Breakpoints } from "../../../utils/breakpoints/breakpoints";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  slim?: boolean;
}

const Container = (props: ContainerProps): ReactElement<ContainerProps> => {
  const { children, slim } = props;
  const maxBreakpoint = useMaxBreakpoint();
  const classNames = [
    styles.container,
    !slim ? styles.slim : "",
    maxBreakpoint > Breakpoints.MD ? styles.addedPadding : "",
  ].join(" ");
  return <div className={classNames}>{children}</div>;
};

Container.defaultProps = {
  slim: false,
};

export default Container;
