import * as inwardTypes from "../inwardTypes/rent";
import * as outwardTypes from "../outwardTypes/rent";

export const holidayMapper = (
  inwardHoliday: inwardTypes.Holiday
): outwardTypes.Holiday => inwardHoliday;

export const holidaysMapper = (
  inwardHolidays: inwardTypes.Holidays
): outwardTypes.Holidays =>
  inwardHolidays.map((inwardHoliday) => holidayMapper(inwardHoliday));

export const carMapper = (
  inwardCarResults: inwardTypes.Car
): outwardTypes.Car => ({
  name: inwardCarResults.name,
  carModelId: inwardCarResults.car_model_id,
  mainImageUrl: inwardCarResults.main_image_url,
  brandName: inwardCarResults.brand_name,
  id: inwardCarResults.car_id,
  modelDescription: {
    doors: inwardCarResults.model_description.doors,
    airConditioning: inwardCarResults.model_description.air_conditioning,
    transmission: inwardCarResults.model_description.transmission,
    capacity: inwardCarResults.model_description.capacity,
  },
  priceInfo: {
    dailyPrice: inwardCarResults.price_info.price,
    totalPrice: inwardCarResults.price_info.total_price,
    hasDiscount: inwardCarResults.price_info.has_discount,
    discount: inwardCarResults.price_info.discount,
    dailyPriceBeforeDiscount: inwardCarResults.price_info.price_before_discount,
    totalPriceBeforeDiscount:
      inwardCarResults.price_info.total_price_before_discount,
  },
});

export const searchResultsMapper = (
  inwardSearchResults: inwardTypes.SearchResults
): outwardTypes.SearchResults => ({
  availableCars: inwardSearchResults.available_cars.map((inwardCar) =>
    carMapper(inwardCar)
  ),
  datesInfo: {
    pickupDate: new Date(inwardSearchResults.initial_date),
    dropOffDate: new Date(inwardSearchResults.final_date),
    durationDays: inwardSearchResults.duration_days,
  },
});

export const reservationResultMapper = (
  reservationResult: inwardTypes.ReservationResult
): outwardTypes.ReservationResult => ({
  amount: reservationResult.amount,
  reservation: reservationResult.reservation_id,
  statusCode: reservationResult.status_code,
  status: reservationResult.status,
  cardNumber: reservationResult.card_number,
  paymentType: reservationResult.payment_type,
});

export const transactionMapper = (
  transaction: inwardTypes.Transaction
): outwardTypes.Transaction => ({
  url: transaction.url,
  tokenWs: transaction.token_ws,
});
