export const title = "Detalles y Tarifa";
export const details = "Detalles";
export const rate = "Tarifa";
export const notIncluded = "* Este precio no incluye:";
export const tag =
  "Cargo por uso de Tag autopistas urbanas e interurbanas (peajes / free Flow) ";
export const gas =
  "Combustibles: Los automóviles se entregan con el estanque lleno y se deben devolver de la misma manera.Se cobrará cualquier diferencia ";
export const smallLetter =
  "**Ten en cuenta que la representación y las especificaciones de los vehículos son solamente una ilustración de ejemplo de la clase de vehículo que alquilas (Exceptuando errores).Una reserva solo es posible para una categoría de vehículo, pero no para un vehículo particular.Los vehículos y modelos están sujetos a disponibilidad y a la sucursal respectiva.**";
export const priceText = "Precio por día: ";
export const includesIva = "Incluye IVA";

export const termsAndConditionsLink = "Ver términos y condiciones";
