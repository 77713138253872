import React, { ReactElement, useEffect, useState } from "react";
import { Row, Col, Radio } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./fleet.scss";
import * as brandsInteractors from "../../store/interactors/brandsInteractors";
import { FleetCarModel } from "../../services/outwardTypes/brands";
import { BrandsState } from "../../store/types/brandsTypes";
import { AppDispatch, RootState } from "../../store";
import CarModelCard from "../../components/fleetComponents/FleetCarModelCard";
import * as texts from "../../assets/texts/carModels";
import Container from "../../components/generalUI/Container";

interface StateProps {
  brandsState: BrandsState;
}

interface DispatchProps {
  getBrandsCarModelsInteractor: typeof brandsInteractors.getBrandsCarModelsInteractor;
  getBrandsInteractor: typeof brandsInteractors.getBrandsInteractor;
}

interface Props extends StateProps, DispatchProps {}

const Fleet = (props: Props): ReactElement => {
  const { brandsState, getBrandsCarModelsInteractor, getBrandsInteractor } =
    props;
  const { brandsCarModels, brands } = brandsState;

  useEffect(() => {
    getBrandsCarModelsInteractor();
  }, [getBrandsCarModelsInteractor]);

  useEffect(() => {
    getBrandsInteractor();
  }, [getBrandsInteractor]);

  const [filter, setFilter] = useState<string>("");
  const onChangeWrapper = (brandId: string) => {
    const onChangeHandler = () => {
      setFilter(brandId);
    };
    return onChangeHandler;
  };
  const carModelsList = Object.values(brandsCarModels).flatMap(
    (models) => models
  );
  const carModelsToShow: FleetCarModel[] = filter
    ? brandsCarModels[filter]
    : carModelsList;

  return (
    <Container>
      <div className="fleetViewTitleContainer">
        <h2 className="title">{texts.title}</h2>
      </div>
      <Radio.Group className="brandsButtonsContainer" buttonStyle="solid">
        {Object.keys(brands).map((brand: string) => (
          <Radio.Button
            key={brand}
            value={brand}
            className="brandButton"
            onChange={onChangeWrapper(brand)}
          >
            {brands[brand].name}
          </Radio.Button>
        ))}
      </Radio.Group>
      <Row className="fleetViewTitleContainer" gutter={[20, 20]}>
        {carModelsToShow.map((model: FleetCarModel) => {
          let brandName = "";
          if (model.brandId && brands[model.brandId]) {
            const brand = brands[model.brandId];
            brandName = brand.name ? brand.name : "";
          }
          return (
            <Col
              key={model.id}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 8 }}
              className="carModelsViewContainer"
            >
              <CarModelCard model={model} brandName={brandName} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    brandsState: state.brands,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...brandsInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fleet);
