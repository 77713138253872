import React, { ReactElement } from "react";
import { Col, Row } from "antd";
import { selectionSummary as texts } from "../../../assets/texts/rent";
import { Car, DatesInfo } from "../../../services/outwardTypes/rent";
import CarSummary from "./CarSummary";
import EventDescription from "./EventDescription";

interface Props {
  columnsSpan: number;
  model: Car;
  dates: DatesInfo | null;
  pickUp: string;
  dropOff: string;
}

const SelectionSummary = (props: Props): ReactElement => {
  const { columnsSpan, model, dates, pickUp, dropOff } = props;
  return (
    <Row>
      <Col span={columnsSpan}>
        <CarSummary model={model} />
      </Col>
      <Col span={columnsSpan}>
        <EventDescription
          columnsNumber={columnsSpan / 24}
          titleText={texts.pickUpHeader}
          subsidiary={pickUp}
          date={dates?.pickupDate}
        />
        <EventDescription
          columnsNumber={columnsSpan / 24}
          titleText={texts.dropOffHeader}
          subsidiary={dropOff}
          date={dates?.dropOffDate}
        />
      </Col>
    </Row>
  );
};

export default SelectionSummary;
