import { Form, FormInstance } from "antd";
import { Dayjs } from "dayjs";
import React, { ReactElement } from "react";
import RangePicker from "../../../generalUI/RangePicker";

interface Props {
  holidays: Array<string>;
  message: string;
  placeholder: [string, string];
  form: FormInstance;
}

const DatesFormItem = (props: Props): ReactElement => {
  const { holidays, message, placeholder, form } = props;
  return (
    <Form.Item
      name="dates"
      rules={[
        {
          required: true,
          message,
        },
      ]}
    >
      <RangePicker
        holidays={holidays}
        onChange={(values): void => form.setFieldsValue(values)}
        value={form.getFieldValue("dates") as [Dayjs, Dayjs]}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default DatesFormItem;
