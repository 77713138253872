import * as inwardTypes from "../inwardTypes/brands";
import * as outwardTypes from "../outwardTypes/brands";

const brandMapper = (inwardBrand: inwardTypes.Brand): outwardTypes.Brand => ({
  name: inwardBrand.name,
});
export const brandsMapper = (
  inwardBrands: inwardTypes.Brands
): outwardTypes.Brands =>
  Object.entries(inwardBrands).reduce(
    (dict, [brandId, inwardBrand]) => ({
      ...dict,
      [brandId]: brandMapper(inwardBrand),
    }),
    {}
  );
const carModelMapper = (
  inwardCarModel: inwardTypes.FleetCarModel
): outwardTypes.FleetCarModel => ({
  id: inwardCarModel.car_model_id,
  name: inwardCarModel.name,
  mainImageUrl: inwardCarModel.main_image_url,
  brandId: inwardCarModel.brand_id,
  priceInfo: {
    price: inwardCarModel.price_info.price,
    hasDiscount: inwardCarModel.price_info.has_discount,
    discount: inwardCarModel.price_info.discount,
    priceBeforeDiscount: inwardCarModel.price_info.price_before_discount,
  },
});
export const brandsCarModelsMapper = (
  inwardBrandsCarModels: inwardTypes.BrandsCarModels
): outwardTypes.BrandsCarModels =>
  Object.entries(inwardBrandsCarModels).reduce(
    (dict, [brandId, inwardCarModels]) => ({
      ...dict,
      [brandId]: inwardCarModels.map((inwardCarModel) =>
        carModelMapper(inwardCarModel)
      ),
    }),
    {}
  );

const familyMapper = (
  inwardFamily: inwardTypes.Family
): outwardTypes.Family => ({
  id: inwardFamily.family_id,
  name: inwardFamily.name,
});

export const brandsFamiliesMapper = (
  inwardBrandsFamilies: inwardTypes.BrandsFamilies
): outwardTypes.BrandsFamilies =>
  Object.entries(inwardBrandsFamilies).reduce(
    (dict, [brandId, inwardFamilies]) => ({
      ...dict,
      [brandId]: inwardFamilies.map((inwardFamily) =>
        familyMapper(inwardFamily)
      ),
    }),
    {}
  );
