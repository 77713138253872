import * as subsidiariesService from "../../services/subsidiariesService";
import { subsidiariesActions } from "../slices/subsidiariesSlice";
import {
  buildInteractorDirectActionNoParams,
  buildInteractorStaticNoParams,
} from "./base";

export const getSubsidiariesInteractor = buildInteractorStaticNoParams(
  subsidiariesActions.loadingGetSubsidiaries,
  subsidiariesActions.successGetSubsidiaries,
  subsidiariesActions.errorGetSubsidiaries,
  subsidiariesService.getSubsidiaries,
  "subsidiaries",
  "subsidiaries"
);

export const resetGetSubsidiariesStatusInteractor =
  buildInteractorDirectActionNoParams(subsidiariesActions.resetGetSubsidiaries);
