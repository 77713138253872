import React, { ReactElement } from "react";
import { Button as ButtonAntd, ButtonProps } from "antd";
import "./button.scss";
import "antd/dist/antd.css";

interface Props extends ButtonProps {
  color: "goldButton" | "blackButton";
}

const Button = (props: Props): ReactElement => {
  const { color } = props;
  return (
    <div className={color}>
      <ButtonAntd {...props} />
    </div>
  );
};

export default Button;
