import React, { ReactElement } from "react";
import { Col, Row } from "antd";
import {
  Capacity,
  CarDoor,
  AirConditioning,
  Transmission,
} from "../../../../assets/icons";
import { Car } from "../../../../services/outwardTypes/rent";
import * as texts from "../../../../assets/texts/availableCarModel";
import styles from "./descriptionContainer.module.scss";

interface Props {
  model: Car;
}

const DescriptionContainer = (props: Props): ReactElement => {
  const { model } = props;
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 24 }}
      md={{ span: 8 }}
      className={styles.descriptionText}
    >
      {model.brandName && model.name && (
        <Row className={styles.carModel} gutter={[20, 20]}>
          <Col className={styles.brand}>{model.brandName}</Col>
          <Col className={styles.model}>{model.name}</Col>
        </Row>
      )}
      {model.modelDescription?.doors && (
        <Row className={styles.carModelDescription} gutter={[2, 2]}>
          <img src={CarDoor} alt="carDoor" className={styles.icon} />
          <Col className={styles.text}>
            {model.modelDescription?.doors} {texts.doorsText}
          </Col>
        </Row>
      )}
      {model.modelDescription?.airConditioning && (
        <Row className={styles.carModelDescription} gutter={[2, 2]}>
          <img
            src={AirConditioning}
            alt="airConditioning"
            className={styles.icon}
          />
          <Col className={styles.text}>
            {model.modelDescription.airConditioning}
          </Col>
        </Row>
      )}
      {model.modelDescription?.transmission && (
        <Row className={styles.carModelDescription} gutter={[2, 2]}>
          <img src={Transmission} alt="transmission" className={styles.icon} />
          <Col className={styles.text}>
            {model.modelDescription.transmission}
          </Col>
        </Row>
      )}
      {model.modelDescription?.capacity && (
        <Row className={styles.carModelDescription} gutter={[2, 2]}>
          <img src={Capacity} alt="capacity" className={styles.icon} />
          <Col className={styles.text}>
            {texts.capacityText} {model.modelDescription?.capacity}{" "}
            {texts.peopleText}
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default DescriptionContainer;
