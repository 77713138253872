import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import { homeReducer } from "./slices/homeSlice";
import { userAuthReducer } from "./slices/userAuthSlice";
import { subsidiariesReducer } from "./slices/subsidiariesSlice";
import { carModelsReducer } from "./slices/carModelsSlice";
import { rentReducer } from "./slices/rentSlice";
import { brandsReducer } from "./slices/brandsSlice";

// Parts of the state you want to persist and level of persistance
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userAuth", "rent"],
};

// Add new reducers here
const RootReducer = combineReducers({
  userAuth: userAuthReducer,
  home: homeReducer,
  subsidiaries: subsidiariesReducer,
  carModels: carModelsReducer,
  rent: rentReducer,
  brands: brandsReducer,
});

const RootReducerPersistor = persistReducer(persistConfig, RootReducer);

// Export the store to use it in the app
/* eslint-disable no-underscore-dangle */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  RootReducerPersistor,
  compose(applyMiddleware(thunk), composeEnhancers())
);
/* eslint-enable */

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof RootReducerPersistor>;
export type AppDispatch = typeof store.dispatch;
export type SliceName =
  | "userAuth"
  | "home"
  | "subsidiaries"
  | "carModels"
  | "rent"
  | "brands";
