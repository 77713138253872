import React, { ReactElement } from "react";
import { Card, Collapse } from "antd";
import RentLayout from "../RentLayout";
import styles from "./responsiveStepContent.module.scss";

export interface Element {
  header: string;
  content: ReactElement;
  defaultActive: boolean;
  siderable: boolean;
  key: string;
  slimTopCard?: boolean;
}
interface Props {
  bodyAndSider: boolean;
  elements: Array<Element>;
}

const ResponsiveStepContent = (props: Props): ReactElement => {
  const { Panel } = Collapse;
  const { bodyAndSider, elements } = props;

  const collapse = (
    <Collapse
      className={bodyAndSider ? styles.siderCollapse : undefined}
      bordered={false}
      defaultActiveKey={elements
        .filter((it) => it.defaultActive)
        .map((it) => it.key)}
    >
      {elements
        .filter((it) => !it.siderable)
        .map((it) => (
          <Panel key={it.key} header={it.header}>
            {it.content}
          </Panel>
        ))}
      {!bodyAndSider &&
        elements
          .filter((it) => it.siderable)
          .map((it) => (
            <Panel key={it.key} header={it.header}>
              {it.content}
            </Panel>
          ))}
    </Collapse>
  );
  const siderRight = bodyAndSider ? collapse : undefined;
  const body = bodyAndSider ? (
    <div>
      {elements
        .filter((it) => it.siderable)
        .map((it) => {
          const className = it.slimTopCard
            ? styles.centeredContent
            : `${styles.centeredContent} ${styles.contentMarginTop}`;
          return (
            <Card key={it.key} title={it.header} className={className}>
              {it.content}
            </Card>
          );
        })}
    </div>
  ) : (
    collapse
  );
  return <RentLayout siderRight={siderRight}>{body}</RentLayout>;
};

export default ResponsiveStepContent;
