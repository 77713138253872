export const steps = [
  { title: "Búsqueda", description: "Elige tu auto" },
  { title: "Datos", description: "Ingresa tus datos" },
  { title: "Checkout", description: "Confirma tu reserva" },
];

export const editSearchButon = "EDITAR BÚSQUEDA";

export const personalInfoStep = {
  selectionHeader: "Tu selección",
  paymentHeader: "Total a pagar: ",
  personalInfoHeader: "Tus Datos",
  rutLabel: "Rut",
  rutPlaceholder: "12.345.678-9",
  rutWarning:
    "El rut de la reserva debe coincidir con el rut asociado al método de pago. Este será sometido a una evaluación crediticia.",
  emailLabel: "Email",
  emailPlaceholder: "email@ejemplo.com",
  nameLabel: "Nombre",
  namePlaceholder: "Nombre",
  lastNameLabel: "Apellido",
  lastNamePlaceholder: "Apellido",
  phoneLabel: "Teléfono",
  phonePlaceholder: "227654321 (opcional)",
  cellPhoneLabel: "Celular",
  cellPhonePlaceholder: "987654321",
  contactMethodLabel: "Contactar por",
  contactPhoneOption: "Teléfono",
  contactEmailOption: "Email",
  nextStepButton: "Ir al siguiente paso",
  invalidEmail: "Ingrese un email válido",
  invalidCellphone: "Ingrese un número válido",
};

export const checkoutStep = {
  selectionHeader: personalInfoStep.selectionHeader,
  personalInfoHeader: personalInfoStep.personalInfoHeader,
  paymentHeader: personalInfoStep.paymentHeader,
  termsModal: {
    title: "Acepta los Términos y Condiciones",
    content:
      "Para poder pagar tu reserva primero debes aceptar los términos y condiciones",
  },

  userDataTerms:
    "El Cliente autoriza a Comercializadora Ditec Automóviles SpA, a compartir su información con empresas asociadas y filiales tanto nacionales como extranjeras, y a contactarlo para enviarle información relevante y/o preguntarle su opinión por la forma en que fueron prestados los servicios. El Cliente declara que ha sido informado acerca del propósito del almacenamiento de sus datos personales y autoriza su tratamiento de conformidad lo regula la ley 19.628 de protección de datos de carácter personal y a su Política de Privacidad y Protección de Datos Personales.",
  accept: "He leído y acepto los ",
  terms: "Términos y Condiciones",
  acceptCookies: " y la  ",
  cookiesLink: " Política de Cookies",
  userExperience: " para mejorar la experiencia de usuario.",

  goToPayment: "Ir a pagar",
};

export const selectionSummary = {
  carHeader: "Auto",
  pickUpHeader: "Retiro",
  dropOffHeader: "Devolución",
  subsidiaryLabel: "Sucursal",
  dateLabel: "Fecha",
};

export const priceSummary = {
  dailyPriceLabel: "Precio por día",
  durationDaysLabel: "Periodo arriendo",
  daysLabel: " días",
};
export const noResults = "No hay autos disponibles";

export const personalInfoSummary = {
  rutLabel: personalInfoStep.rutLabel,
  emailLabel: personalInfoStep.emailLabel,
  nameLabel: personalInfoStep.nameLabel,
  lastNameLabel: personalInfoStep.lastNameLabel,
  phoneLabel: personalInfoStep.phoneLabel,
  cellPhoneLabel: personalInfoStep.cellPhoneLabel,
  contactMethodLabel: personalInfoStep.contactMethodLabel,
};
