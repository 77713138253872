import { validateRut } from "@abstract-cl/rut-ts";
import { RuleObject } from "antd/lib/form";

const rutFormValidator = (rule: RuleObject, value: string): Promise<void> => {
  if (validateRut(value) || value === "") {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Ingrese un rut válido!"));
};

export default rutFormValidator;
