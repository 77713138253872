import { Grid } from "antd";
import screenToInt from "./breakpoints";

const useMaxBreakpoint = (): number => {
  const screens = Grid.useBreakpoint();
  const screensArray = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => screenToInt(screen[0]));
  const screensMax = Math.max(...screensArray);
  return screensMax;
};
export default useMaxBreakpoint;
