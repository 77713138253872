import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import priceFormat from "utils/formats/priceFormat";
import redirectToWebpay from "utils/webpay/redirectToWebpay";
import subsidiaryName from "utils/formats/subsidiaryName";
import { SubsidiariesState } from "store/types/subsidiariesTypes";
import { Checkbox, Row, Col } from "antd";
import { TermsFile, CookiesFile } from "assets/files";
import { warningModal } from "components/generalUI/FeedbackModal/FeedbackModal";
import PersonalInfoSummary from "./PersonalInfoSummary";
import { Breakpoints } from "../../../utils/breakpoints/breakpoints";
import useMaxBreakpoint from "../../../utils/breakpoints/useMaxBreakpoint";
import PriceSummary from "../PriceSummary";
import SelectionSummary from "../SelectionSummary";
import styles from "./step3.module.scss";
import { checkoutStep as texts } from "../../../assets/texts/rent";
import { AppDispatch, RootState } from "../../../store";
import { RentState } from "../../../store/types/rentTypes";
import Button from "../../generalUI/Button";
import * as rentInteractors from "../../../store/interactors/rentInteractors";
import ResponsiveStepContent, { Element } from "../ResponsiveStepContent";

interface StateProps {
  rentState: RentState;
  subsidiariesState: SubsidiariesState;
}

interface DispatchProps {
  postTransactionInteractor: typeof rentInteractors.postTransactionInteractor;
}

interface Props extends StateProps, DispatchProps {}

const RentCheckout = (props: Props): ReactElement => {
  const { rentState, subsidiariesState, postTransactionInteractor } = props;
  const {
    datesInfo,
    transaction,
    postTransactionStatus,
    pickUp,
    dropOff,
    carSelection,
    personalInformation,
  } = rentState;
  const { subsidiaries } = subsidiariesState;
  const { success, loading } = postTransactionStatus;
  const [acceptTerms, SetAcceptTerms] = useState(false);

  useEffect(() => {
    if (success && transaction) {
      redirectToWebpay(transaction.url, transaction.tokenWs);
    }
  }, [success]);

  const paymentButtonOnClick = () => {
    if (acceptTerms) {
      postTransactionInteractor({
        reservationInfo: {
          amount: carSelection?.priceInfo.totalPrice,
          carID: carSelection?.id,
          datesInfo,
          pickUp,
          dropOff,
        },
        clientInfo: personalInformation,
      });
    } else {
      warningModal(texts.termsModal.title, texts.termsModal.content);
    }
  };
  const onAcceptTerms = () => {
    SetAcceptTerms(!acceptTerms);
  };
  const maxBreakpoint = useMaxBreakpoint();
  let columnsSpan = 24;
  let bodyAndSider = false;
  let overridenPersonalInfoColumns = null;
  if (maxBreakpoint >= Breakpoints.SM) {
    columnsSpan = 12;
  }
  if (maxBreakpoint >= Breakpoints.LG) {
    columnsSpan = 24;
    bodyAndSider = true;
    overridenPersonalInfoColumns = 2;
  }
  if (maxBreakpoint >= Breakpoints.XXL) {
    overridenPersonalInfoColumns = 3;
  }
  const columnsNumber = 24 / columnsSpan;
  const totalPriceText = priceFormat(carSelection?.priceInfo.totalPrice);

  const personalInfoSummary = personalInformation && (
    <PersonalInfoSummary
      columnsNumber={overridenPersonalInfoColumns || columnsNumber}
      info={personalInformation}
    />
  );
  const priceSummary = (
    <div>
      {carSelection?.priceInfo && (
        <PriceSummary
          columnsNumber={columnsNumber}
          priceInfo={carSelection?.priceInfo}
          duration={datesInfo?.durationDays}
        />
      )}
      <Row className={styles.termsContainer}>
        <Col span={24}>
          <p className={styles.smallLetter}>{texts.userDataTerms}</p>
        </Col>
      </Row>
      <Row className={styles.termsContainer}>
        <Col span={1}>
          <Checkbox
            checked={acceptTerms}
            onChange={onAcceptTerms}
            className={styles.checkBox}
          />
        </Col>
        <Col span={21}>
          <p className={styles.termsText}>
            {texts.accept}
            <a
              href={TermsFile as string}
              className={styles.termsLink}
              target="_blank"
              rel="noreferrer"
            >
              {texts.terms}
            </a>
            {texts.acceptCookies}
            <a
              href={CookiesFile as string}
              className={styles.termsLink}
              target="_blank"
              rel="noreferrer"
            >
              {texts.cookiesLink}
            </a>
            {texts.userExperience}
          </p>
        </Col>
      </Row>
      <div className={styles.endButton}>
        <Button
          htmlType="submit"
          color="goldButton"
          onClick={paymentButtonOnClick}
          loading={loading}
        >
          {texts.goToPayment}
        </Button>
      </div>
    </div>
  );
  const selectionSummary = carSelection && (
    <SelectionSummary
      columnsSpan={columnsSpan}
      model={carSelection}
      dates={datesInfo}
      pickUp={subsidiaryName(subsidiaries, pickUp)}
      dropOff={subsidiaryName(subsidiaries, dropOff)}
    />
  );

  const elements = carSelection && [
    {
      key: "0",
      header: texts.personalInfoHeader,
      defaultActive: false,
      siderable: true,
      content: personalInfoSummary,
    },
    {
      key: "1",
      header: texts.selectionHeader,
      defaultActive: true,
      siderable: false,
      content: selectionSummary,
    },
    {
      key: "2",
      header: texts.paymentHeader + totalPriceText,
      defaultActive: !bodyAndSider,
      siderable: true,
      content: priceSummary,
      slimTopCard: true,
    },
  ];
  return (
    <ResponsiveStepContent
      bodyAndSider={bodyAndSider}
      elements={elements as Element[]}
    />
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    rentState: state.rent,
    subsidiariesState: state.subsidiaries,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...rentInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RentCheckout);
