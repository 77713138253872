import React, { ReactElement, useState, useRef, useEffect } from "react";
import { Image as BaseImage, Skeleton } from "antd";
import { ImageProps } from "antd/lib/image";
import styles from "./imageWithLoader.module.scss";

interface CustomImageProps extends ImageProps {
  loadAnimation?: boolean;
}

const ImageWithLoader = ({
  loadAnimation,
  ...otherProps
}: CustomImageProps): ReactElement<CustomImageProps> => {
  const [isLoading, setIsLoading] = useState(true);
  const [skeletonContainerWidth, setSkeletonContainerWidth] = useState(100);
  const skeletonContainerRef = useRef<HTMLDivElement>(null);

  const displayImage = isLoading ? "none" : "block";
  const displayLoader = isLoading ? "block" : "none";

  useEffect(() => {
    if (
      !!skeletonContainerRef &&
      !!skeletonContainerRef.current &&
      !!skeletonContainerRef.current.parentElement
    ) {
      setSkeletonContainerWidth(
        skeletonContainerRef.current.parentElement.offsetWidth
      );
    }
  });

  return (
    <>
      <div
        style={{ display: displayLoader }}
        ref={skeletonContainerRef}
        className={styles.skeletonContainer}
      >
        <Skeleton.Avatar
          active
          shape="square"
          size={skeletonContainerWidth}
          className={styles.skeleton}
        />
      </div>
      <div style={{ display: displayImage }}>
        <BaseImage
          {...otherProps}
          onLoad={() => setIsLoading(false)}
          preview={false}
        />
      </div>
    </>
  );
};

ImageWithLoader.defaultProps = {
  loadAnimation: true,
};

export default ImageWithLoader;
