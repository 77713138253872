export const aboutUsTitle = "Sobre Nosotros";

export const aboutUsContent = [
  "Servicio personalizado que busca cubrir las necesidades de los actuales y potenciales clientes, quienes podrán disfrutar de una experiencia diferente, gracias a la amplia gama de vehículos que sólo Ditec Car Rental puede ofrecer.",
  " Un servicio distinto para personas, empresas, turistas y todo aquel que necesite arrendar un automóvil Premium.",
  "Contamos con una flota de alquiler considerando las cuatro marcas cubriendo gran parte de los segmentos de la industria automotriz.",
];

export const catalogueTitle = "MIRA NUESTROS MODELOS";
export const catalogueSubtitle = "Selecciona el que se ajuste a ti";
export const catalogueButton = "DESCARGAR CATÁLOGO";

export const newsletterTitle =
  "¿Quieres recibir exclusivas ofertas en tu mail?";
export const newsletterPlaceholder = "Ingresa tu Email";
export const newsletterButton = "SUSCRIBIRME";
export const newsletterInvalid = "Ingresa un Email válido";

export const newsletterSuccess = {
  title: "TE HAS SUSCRITO EXITOSAMENTE!",
  text: "A partir de ahora recibirás ofertas exclusivas en tu mail.",
};
