import React, { ReactElement } from "react";
import { Card, Row, Col } from "antd";
import { Logo } from "assets/images";
import { Car } from "services/outwardTypes/rent";
import { selectionSummary as texts } from "../../../../assets/texts/rent";
import styles from "./carSummary.module.scss";

interface Props {
  model: Car;
}

const CarSummary = (props: Props): ReactElement => {
  const { model } = props;
  return (
    <Card type="inner" title={texts.carHeader}>
      <Card
        className={styles.carInfo}
        bordered={false}
        style={{ padding: "0px" }}
      >
        <Row justify="center" align="middle" className={styles.imageContainer}>
          <Col span={24} style={{ textAlign: "center" }}>
            {model?.mainImageUrl ? (
              <img src={model.mainImageUrl} alt="model" />
            ) : (
              <img src={Logo} alt="brand" />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h5>
              {model?.brandName} {model?.name}
            </h5>
          </Col>
        </Row>
      </Card>
    </Card>
  );
};

export default CarSummary;
