import { createSlice } from "@reduxjs/toolkit";
import { UserAuthState } from "../types/userAuthTypes";
import { baseRequestStatusReducers } from "./base";

const initialState: UserAuthState = {
  userAuth: {
    id: null,
    email: null,
    name: null,
  },
  loginUserAuthStatus: {
    loading: false,
    success: false,
    error: false,
  },
  registerUserAuthStatus: {
    loading: false,
    success: false,
    error: false,
  },
  logoutUserAuthStatus: {
    loading: false,
    success: false,
    error: false,
  },
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    ...baseRequestStatusReducers<UserAuthState>("loginUserAuth", "userAuth"),
    ...baseRequestStatusReducers<UserAuthState>("logoutUserAuth", "userAuth"),
    ...baseRequestStatusReducers<UserAuthState>("registerUserAuth", "userAuth"),
  },
});

export const userAuthReducer = userAuthSlice.reducer;
export const userAuthActions = userAuthSlice.actions;
