import * as homeService from "../../services/homeService";
import { homeActions } from "../slices/homeSlice";
import {
  buildInteractor,
  buildInteractorDirectActionNoParams,
  buildInteractorStaticNoParams,
} from "./base";

export const getPromotionsInteractor = buildInteractorStaticNoParams(
  homeActions.loadingGetPromotions,
  homeActions.successGetPromotions,
  homeActions.errorGetPromotions,
  homeService.getPromotions,
  "home",
  "promotions"
);

export const resetGetPromotionsStatusInteractor =
  buildInteractorDirectActionNoParams(homeActions.resetGetPromotions);

export const postNewsletterInteractor = buildInteractor(
  homeActions.loadingPostNewsletter,
  homeActions.successPostNewsletter,
  homeActions.errorPostNewsletter,
  homeService.postNewsletter
);
export const resetPostNewsletterStatusInteractor =
  buildInteractorDirectActionNoParams(homeActions.resetPostNewsletter);

export const getAnnouncementInteractor = buildInteractorStaticNoParams(
  homeActions.loadingGetAnnouncement,
  homeActions.successGetAnnouncement,
  homeActions.errorGetAnnouncement,
  homeService.getAnnouncement,
  "home",
  "announcement"
);

export const resetGetAnnouncementStatusInteractor =
  buildInteractorDirectActionNoParams(homeActions.resetGetAnnouncement);
