import { Form, Select } from "antd";
import React, { ReactElement } from "react";
import { Subsidiaries } from "../../../../services/outwardTypes/subsidiaries";

interface Props {
  name: string;
  subsidiaries: Subsidiaries;
  message: string;
  placeholder: string;
}

const SubsidiaryFormItem = (props: Props): ReactElement => {
  const { name, subsidiaries, message, placeholder } = props;
  const { Option } = Select;
  return (
    <Form.Item name={name} rules={[{ required: true, message }]}>
      <Select placeholder={placeholder}>
        {subsidiaries.map((subsidiary): ReactElement => {
          return (
            <Option value={subsidiary.id} key={subsidiary.id}>
              {subsidiary.address}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default SubsidiaryFormItem;
