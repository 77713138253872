import { Card, Descriptions } from "antd";
import React, { ReactElement } from "react";
import { PersonalInformation } from "services/outwardTypes/rent";
import contactMethodFormat from "utils/formats/contactMethodFormat";
import { personalInfoSummary as texts } from "../../../../assets/texts/rent";

interface Props {
  columnsNumber?: number | undefined;
  info: PersonalInformation;
}

const PersonalInfoSummary = (props: Props): ReactElement => {
  const { columnsNumber, info } = props;
  return (
    <Card type="inner">
      <Descriptions column={columnsNumber && columnsNumber}>
        <Descriptions.Item label={texts.rutLabel}>{info.rut}</Descriptions.Item>
        <Descriptions.Item label={texts.emailLabel}>
          {info.email}
        </Descriptions.Item>
        <Descriptions.Item label={texts.nameLabel}>
          {info.name}
        </Descriptions.Item>
        <Descriptions.Item label={texts.lastNameLabel}>
          {info.lastName}
        </Descriptions.Item>
        <Descriptions.Item label={texts.phoneLabel}>
          {info.phoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label={texts.cellPhoneLabel}>
          {info.cellPhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label={texts.contactMethodLabel}>
          {contactMethodFormat(info.contactMethod)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

PersonalInfoSummary.defaultProps = {
  columnsNumber: undefined,
};

export default PersonalInfoSummary;
