/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactElement, useRef } from "react";
import { CarModel } from "../../../services/outwardTypes/carModels";
import styles from "./carModelSlideshow.module.scss";
import Image from "../../generalUI/ImageWithLoader";

type Props = {
  carModel: CarModel;
};

const CarModelSlideshow = (props: Props): ReactElement => {
  const { carModel } = props;
  const { imagesUrls } = carModel;
  const scrollDivRef = useRef<HTMLDivElement>(null);
  if (!imagesUrls || imagesUrls.length < 1) {
    return <></>;
  }
  const [firstImage] = imagesUrls;

  const scrollDown = () => {
    if (scrollDivRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      scrollDivRef.current.scrollIntoView();
    }
  };

  return (
    <div>
      <Image src={firstImage} width="100%" className={styles.image} />
      <div className={styles.container}>
        <div
          className={styles.scroll}
          role="navigation"
          onClick={() => scrollDown()}
        />
        <div ref={scrollDivRef} />
      </div>
    </div>
  );
};

export default CarModelSlideshow;
