import * as inwardTypes from "../inwardTypes/home";
import * as outwardTypes from "../outwardTypes/home";

export const announcementMapper = (
  inwardAnnouncement: inwardTypes.Announcement
): outwardTypes.Announcement => ({
  url: inwardAnnouncement?.file.url || "",
});

export const promotionsMapper = (
  inwardPromotions: inwardTypes.Promotions
): outwardTypes.Promotions =>
  inwardPromotions.map(
    (inwardPromotion): outwardTypes.Promotion => ({
      url: inwardPromotion.file.url,
    })
  );
