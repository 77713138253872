import React, { ReactElement } from "react";
import { Card, Col, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Price from "components/sharedComponents/Price";
import styles from "./availableCarModelCard.module.scss";
import * as texts from "../../../assets/texts/availableCarModel";
import Button from "../../generalUI/Button";
import { Car, DatesInfo } from "../../../services/outwardTypes/rent";
import priceFormat from "../../../utils/formats/priceFormat";
import DescriptionContainer from "../../sharedComponents/CarModel/DescriptionContainer";

interface Props {
  model: Car;
  datesInfo: DatesInfo | null;
  nextStep: (model: Car) => void;
}

const AvailableCarModelCard = (props: Props): ReactElement => {
  const { model, datesInfo, nextStep } = props;

  return (
    <div className={styles.modelsContainer}>
      <Card
        className={styles.modelsContent}
        bodyStyle={{ padding: "0%" }}
        bordered={false}
      >
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            className={styles.imageContainer}
          >
            <img
              src={model.mainImageUrl}
              alt="model"
              className={styles.image}
            />
            <Row className={styles.infoText}>
              <Col span={24}>
                <a
                  href={`/car-model/${model.carModelId}`}
                  target="_blank"
                  className={styles.text}
                  rel="noreferrer"
                >
                  <InfoCircleOutlined className={styles.icon} />{" "}
                  {texts.moreInfoText}
                </a>
              </Col>
            </Row>
          </Col>
          <DescriptionContainer model={model} />
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            className={styles.prices}
          >
            {model.priceInfo.dailyPrice && (
              <Row className={styles.priceText}>
                {texts.priceText}
                {priceFormat(model.priceInfo.dailyPrice)}
                {texts.timesText}
                {datesInfo?.durationDays}
                {texts.dayText}
              </Row>
            )}
            {model.priceInfo.totalPrice && (
              <Row className={styles.priceText}>
                {model.priceInfo.totalPrice !== 0 &&
                  model.priceInfo.totalPrice && (
                    <Price
                      hasDiscount={model.priceInfo.hasDiscount}
                      priceText={texts.totalPriceText}
                      price={model.priceInfo.totalPrice}
                      priceBeforeDiscountText={texts.totalPriceText}
                      priceBeforeDiscount={
                        model.priceInfo.totalPriceBeforeDiscount
                      }
                    />
                  )}
              </Row>
            )}
            <Row className={styles.selectButtonContainer}>
              <Button
                color="goldButton"
                onClick={() => nextStep(model)}
                className={styles.selectButton}
              >
                {texts.selectText}
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AvailableCarModelCard;
