import * as inwardTypes from "../inwardTypes/subsidiaries";
import * as outwardTypes from "../outwardTypes/subsidiaries";

// eslint-disable-next-line import/prefer-default-export
export const subsidiariesMapper = (
  inwardSubsidiaries: inwardTypes.Subsidiaries
): outwardTypes.Subsidiaries =>
  inwardSubsidiaries.map((inwardSubsidiary) => ({
    id: inwardSubsidiary.id,
    name: inwardSubsidiary.name,
    address: inwardSubsidiary.address,
    publicName: inwardSubsidiary.public_name,
    email: inwardSubsidiary.email,
    phone: inwardSubsidiary.phone,
    attentionSchedule: inwardSubsidiary.attention_schedule,
    latitude: inwardSubsidiary.latitude,
    longitude: inwardSubsidiary.longitude,
  }));
