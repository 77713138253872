import React, { ReactElement } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Menu } from "antd";
import { MenuMode, MenuInfo } from "rc-menu/lib/interface";
import "antd/dist/antd.css";
import "./NavMenu.scss";

interface Props {
  mode: MenuMode;
  onClose: () => void;
}

const NavMenu = ({ mode, onClose }: Props): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const onClick = (e: MenuInfo): void => {
    history.push(e.key);
    onClose();
  };
  return (
    <div className="nav">
      <Menu mode={mode} onClick={onClick} selectedKeys={[location.pathname]}>
        <Menu.Item key="/">
          <Link to="/">
            <span>CAR RENTAL</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/fleet">
          <Link to="/fleet">
            <span>FLOTA</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/subsidiaries">
          <Link to="/subsidiaries">
            <span>SUCURSALES</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/faq">
          <Link to="/faq">
            <span>PREGUNTAS FRECUENTES</span>
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default NavMenu;
