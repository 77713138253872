import * as rentService from "../../services/rentService";
import { rentActions } from "../slices/rentSlice";
import {
  buildInteractor,
  buildInteractorDirectAction,
  buildInteractorDirectActionNoParams,
  buildInteractorStaticNoParams,
} from "./base";

export const getHolidaysInteractor = buildInteractorStaticNoParams(
  rentActions.loadingGetHolidays,
  rentActions.successGetHolidays,
  rentActions.errorGetHolidays,
  rentService.getHolidays,
  "rent",
  "holidays"
);

export const resetGetHolidaysStatusInteractor =
  buildInteractorDirectActionNoParams(rentActions.resetGetHolidays);

export const getSearchResultsInteractor = buildInteractor(
  rentActions.loadingGetSearchResults,
  rentActions.successGetSearchResults,
  rentActions.errorGetSearchResults,
  rentService.getSearchResults
);
export const resetGetSearchResultsStatusInteractor =
  buildInteractorDirectActionNoParams(rentActions.resetGetSearchResults);

export const getReservationResultInteractor = buildInteractor(
  rentActions.loadingGetReservationResult,
  rentActions.successGetReservationResult,
  rentActions.errorGetReservationResult,
  rentService.getReservationResult
);
export const resetGetReservationResultStatusInteractor =
  buildInteractorDirectActionNoParams(rentActions.resetGetReservationResult);

export const postTransactionInteractor = buildInteractor(
  rentActions.loadingPostTransaction,
  rentActions.successPostTransaction,
  rentActions.errorPostTransaction,
  rentService.postTransaction
);
export const resetPostTransactionStatusInteractor =
  buildInteractorDirectActionNoParams(rentActions.resetPostTransaction);

export const setCurrentStepIndexInteractor = buildInteractorDirectAction(
  rentActions.setCurrentStepIndex
);
export const setCarSelectionInteractor = buildInteractorDirectAction(
  rentActions.setCarSelection
);
export const setPickUpInteractor = buildInteractorDirectAction(
  rentActions.setPickUp
);
export const setDropOffInteractor = buildInteractorDirectAction(
  rentActions.setDropOff
);
export const setPersonalInformationInteractor = buildInteractorDirectAction(
  rentActions.setPersonalInformation
);

export const setRentUrlInteractor = buildInteractorDirectAction(
  rentActions.setRentUrl
);

export const resetRentStateInteractor = buildInteractorDirectActionNoParams(
  rentActions.resetRentState
);
