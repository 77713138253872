import React, { ReactElement, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Container from "../../components/generalUI/Container";
import VoucherCard from "../../components/voucherComponents/VoucherCard";
import { RentState } from "../../store/types/rentTypes";
import * as rentInteractors from "../../store/interactors/rentInteractors";
import { AppDispatch, RootState } from "../../store";

interface Params {
  transaction: string;
}
interface StateProps {
  rentState: RentState;
}

interface DispatchProps {
  getReservationResultInteractor: typeof rentInteractors.getReservationResultInteractor;
  resetRentStateInteractor: typeof rentInteractors.resetRentStateInteractor;
  resetPostTransactionStatusInteractor: typeof rentInteractors.resetPostTransactionStatusInteractor;
}

interface Props extends StateProps, DispatchProps {}

const Voucher = (props: Props): ReactElement => {
  const {
    rentState,
    getReservationResultInteractor,
    resetRentStateInteractor,
    resetPostTransactionStatusInteractor,
  } = props;
  const { reservationResult, getReservationResultStatus, rentUrl } = rentState;
  const { transaction } = useParams<Params>();
  const history = useHistory();
  const { error } = getReservationResultStatus;

  const backToHome = () => {
    history.push("/");
    resetRentStateInteractor();
  };
  const tryAgain = () => {
    history.push(rentUrl);
  };

  useEffect(() => {
    getReservationResultInteractor({ transaction });
    resetPostTransactionStatusInteractor();
  }, [transaction]);

  useEffect(() => {
    if (error) {
      backToHome();
    }
  }, [error]);

  return (
    <Container>
      <VoucherCard
        data={reservationResult}
        backToHome={backToHome}
        tryAgain={tryAgain}
      />
    </Container>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    rentState: state.rent,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...rentInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Voucher);
