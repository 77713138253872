import { Card, Row } from "antd";
import React, { ReactElement } from "react";
import { aboutUsContent, aboutUsTitle } from "../../../assets/texts/home";
import "./aboutUs.scss";

const AboutUs = (): ReactElement => {
  return (
    <Card className="cardGrid">
      <h3 className="title">{aboutUsTitle}</h3>
      <Row>
        <Card className="grayCard" hoverable>
          {aboutUsContent[0]}
        </Card>
      </Row>
      <Row className="rowStyle">
        <Card className="whiteCard" hoverable>
          {aboutUsContent[1]}
        </Card>
      </Row>
    </Card>
  );
};

export default AboutUs;
