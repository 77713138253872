import * as texts from "../../assets/texts/utils";

const contactMethodFormat = (
  contactMethod: "email" | "phone" | string
): string => {
  switch (contactMethod) {
    case "email":
      return texts.contactMethods.email;
    case "phone":
      return texts.contactMethods.phone;
    default:
      return contactMethod;
  }
};

export default contactMethodFormat;
