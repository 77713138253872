/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import * as _ from "lodash";
import { AppDispatch, store, SliceName } from "..";
import { errorModal } from "../../components/generalUI/FeedbackModal/FeedbackModal";
import * as texts from "../../assets/texts/errors";

export const errorTimeout = 1000 * 3; // 3 second timeout

let activeError = false;
setTimeout(() => {
  activeError = false;
}, errorTimeout);

const errorHandler = (error: Error): string => {
  const finalErrorMessage = error.message;
  if (!activeError) {
    activeError = true;
    errorModal(texts.title, texts.content, finalErrorMessage);
  }
  return finalErrorMessage;
};

export const buildInteractor =
  (
    loadingAction: ActionCreatorWithPayload<void>,
    successAction: ActionCreatorWithPayload<unknown>,
    errorAction: ActionCreatorWithPayload<unknown>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: ((args: any) => Promise<unknown>) | null
  ) =>
  (params: unknown) => {
    return async function interactor(dispatch: AppDispatch): Promise<void> {
      dispatch(loadingAction());
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await request!(params);
        dispatch(successAction(response));
      } catch (error) {
        const composedErrorMessage = errorHandler(error as Error);
        dispatch(errorAction(composedErrorMessage));
      }
    };
  };

export const buildInteractorNoParams =
  (
    loadingAction: ActionCreatorWithPayload<void>,
    successAction: ActionCreatorWithPayload<unknown>,
    errorAction: ActionCreatorWithPayload<unknown>,
    request: (() => Promise<unknown>) | null
  ) =>
  () => {
    return async function interactor(dispatch: AppDispatch): Promise<void> {
      dispatch(loadingAction());
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const response = await request!();
        dispatch(successAction(response));
      } catch (error) {
        const composedErrorMessage = errorHandler(error as Error);
        dispatch(errorAction(composedErrorMessage));
      }
    };
  };

export const buildInteractorDirectAction =
  (request: ActionCreatorWithPayload<unknown>) => (params: unknown) => {
    return (dispatch: AppDispatch): void => {
      dispatch(request(params));
    };
  };

export const buildInteractorDirectActionNoParams =
  (request: ActionCreatorWithPayload<void>) => () => {
    return (dispatch: AppDispatch): void => {
      dispatch(request());
    };
  };

export const buildInteractorStatic =
  (
    loadingAction: ActionCreatorWithPayload<void>,
    successAction: ActionCreatorWithPayload<unknown>,
    errorAction: ActionCreatorWithPayload<unknown>,
    sliceName: SliceName,
    resourceName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: ((args: any) => Promise<unknown>) | null
  ) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (params: unknown) => {
    const state = store.getState();
    const resource = state[sliceName][resourceName];
    return async function interactor(dispatch: AppDispatch): Promise<void> {
      dispatch(loadingAction());
      try {
        let response = await resource;
        if (_.isEmpty(resource)) {
          response = await request?.(params);
        }
        dispatch(successAction(response));
      } catch (error) {
        const composedErrorMessage = errorHandler(error as Error);
        dispatch(errorAction(composedErrorMessage));
      }
    };
  };

export const buildInteractorStaticNoParams =
  (
    loadingAction: ActionCreatorWithPayload<void>,
    successAction: ActionCreatorWithPayload<unknown>,
    errorAction: ActionCreatorWithPayload<unknown>,
    request: (() => Promise<unknown>) | null,
    sliceName: SliceName,
    resourceName: string
  ) =>
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  () => {
    const state = store.getState();
    const resource = state[sliceName][resourceName];
    return async function interactor(dispatch: AppDispatch): Promise<void> {
      dispatch(loadingAction());
      try {
        let response = await resource;
        if (_.isEmpty(resource)) {
          response = await request?.();
        }
        dispatch(successAction(response));
      } catch (error) {
        const composedErrorMessage = errorHandler(error as Error);
        dispatch(errorAction(composedErrorMessage));
      }
    };
  };
