import React from "react";
import ReactDOM from "react-dom";
import "./assets/globalStyles/main.scss";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import { BrowserRouter as Router } from "react-router-dom"; // Import Router
import App from "./App";
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={esES}>
      <Router>
        <App />
      </Router>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
