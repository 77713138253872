import React, { ReactElement } from "react";
import "antd/dist/antd.css";
import dayjs, { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";
import "antd/es/date-picker/style/index";
import updateLocale from "dayjs/plugin/updateLocale";
import isoWeek from "dayjs/plugin/isoWeek";
import "dayjs/locale/es";
import "./rangePicker.scss";
import * as texts from "../../../assets/texts/reservationForm";

interface Props {
  holidays: Array<string>;
  onChange: (values: unknown) => void | undefined;
  value: [Dayjs, Dayjs];
  placeholder: [string, string];
}

const RangePicker = (props: Props): ReactElement => {
  const { holidays, onChange, value, placeholder } = props;
  const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);
  const RangePickerAntd = DatePicker.RangePicker;
  dayjs.extend(updateLocale);
  dayjs.extend(isoWeek);
  dayjs.updateLocale("es", {
    monthsShort: texts.months,
    week: {
      dow: 1,
    },
  });

  const disabledDate = (current: Dayjs): boolean => {
    const today = dayjs();
    const pastDays =
      current < today &&
      today.format("DD/MM/YYYY") !== current.format("DD/MM/YYYY");
    const weekends = current.isoWeekday() === 6 || current.isoWeekday() === 7;
    const holiday = holidays.includes(current.format("DD/MM/YYYY"));
    return pastDays || weekends || holiday;
  };

  return (
    <RangePickerAntd
      placeholder={placeholder}
      disabledDate={disabledDate}
      onChange={onChange}
      value={value}
      className="RangePicker"
    />
  );
};
export default RangePicker;
