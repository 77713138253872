/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, { AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import { DecodedToken } from "./inwardTypes/userAuth";
import * as inwardTypes from "./inwardTypes/errors";
import * as texts from "../assets/texts/errors";

export const serviceTimeout = 1000 * 20; // 20 second timeout

const baseConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
  serviceTimeout,
  accessTokenItem: `com.${process.env.REACT_APP_APP_NAME || ""}.accessToken`,
  refreshTokenItem: `com.${process.env.REACT_APP_APP_NAME || ""}.refreshToken`,
};

export const axiosBaseInstance = axios.create({
  baseURL: baseConfig.baseUrl,
  timeout: serviceTimeout,
  headers: {
    token: process.env.REACT_APP_API_TOKEN,
  },
});

export const getDecodedAccessToken = (): DecodedToken | null => {
  const token = localStorage.getItem(baseConfig.accessTokenItem);
  return token ? jwtDecode(token) : null;
};
type ResponseType = AxiosResponse<
  inwardTypes.SuccessTypeError | inwardTypes.GeneralTypeError
>;

type ErrorType = {
  config: { headers: { [key: string]: unknown } };
  response: { status: number; statusText: string };
};

const responseHandler = (
  response: ResponseType
): AxiosResponse<
  inwardTypes.SuccessTypeError | inwardTypes.GeneralTypeError
> => {
  if (response.data?.status && response.data?.errors) {
    const code = `${response.data.status} ${response.data.errors}`;
    throw new Error(code);
  }
  return response;
};

const errorHandler = (error: ErrorType) => {
  let code = texts.connectionError;
  if (error?.response) {
    code = `${error.response.status} ${error.response.statusText}`;
    throw new Error(code);
  }
  throw new Error(code);
};

axiosBaseInstance.interceptors.response.use(
  (response) => responseHandler(response as ResponseType),
  (error) => errorHandler(error as ErrorType)
);

export const headerWithAuthorization = (): { [key: string]: string } => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      localStorage.getItem(baseConfig.accessTokenItem) || ""
    }`,
  };
};

export const headerWithCustomAuthorization = (
  userToken: string
): { [key: string]: string } => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };
};

export const headerWithAuthorizationForGraphql = (): {
  [key: string]: string;
} => {
  return {
    Authorization: `Bearer ${
      localStorage.getItem(baseConfig.accessTokenItem) || ""
    }`,
  };
};

export const headerWithAuthorizationMultipart = (): {
  [key: string]: string;
} => {
  return {
    Authorization: `Bearer ${
      localStorage.getItem(baseConfig.accessTokenItem) || ""
    }`,
  };
};
