export const title = "PREGUNTAS FRECUENTES";
export const content = [
  {
    title: "¿Cuáles son los Requisitos para arrendar?",
    texts: [
      "• Ser mayor de 25 años. ",
      "• Ciudadanos Chilenos: Cédula Nacional de Identidad y Licencia de conducir vigente.",
      "• Ciudadanos Extranjeros: Documento de Identificación o Pasaporte y Licencia del país de origen o internacional vigente. Esto aplica para extranjeros en calidad de turista en Chile.",
      "• Ciudadanos extranjeros que ya hayan tramitado la Cédula de Identidad Chilena, para residencia y/o trabajo, deben de igual forma tramitar y obtener la Licencia de Conducir chilena. Los ciudadanos extranjeros con residencia en Chile, que se encuentren realizando trabajos temporales o permanentes y no se encuentren en calidad de turista, deben poseer Licencia de Conducir chilena, emitida por alguna de las Municipalidades autorizadas para este trámite.",
      "• Disponer de Tarjeta de Crédito para efectos de garantía del vehículo a arrendar.",
    ],
  },
  {
    title:
      "¿Es necesario dejar alguna garantía al momento de arrendar un vehículo?",
    texts: [
      "• El respaldo de la garantía es un requisito obligatorio que el Titular del arriendo debe realizar, únicamente, mediante tarjeta de crédito bancaria, no se aceptan tarjetas ligadas a casas comerciales. El monto mínimo para garantizar el arriendo es desde UF20+IVA, (el monto de la garantía dependerá de la cantidad de días de arriendo y el vehículo seleccionado), presentando su Cédula de Identidad y Licencia de Conducir vigentes.",
    ],
  },
  {
    title: "¿Cuáles son las opciones de pago?",
    texts: [
      "Tarjetas de Crédito o Débito: American Express, MasterCard, Visa, Diners.",
    ],
  },
  {
    title: "¿Existen restrinciones Geográficas?",
    texts: [
      "La conducción de vehículos es restringida a la República de Chile.",
    ],
  },
  {
    title:
      "¿Hay algún recargo adicional a los valores indicados en la página web?",
    texts: [
      "Se aplica un cargo por uso de autopistas urbanas e interurbanas (peajes/free flow).",
    ],
  },
  {
    title:
      "¿Cuál es el valor del deducible en caso de sinestro del vehículo en arriendo?",
    texts: [
      "•	En caso de daño o siniestro del vehículo se fija un deducible UF20+IVA.",
      "•	En el caso de robo, hurto o pérdida total se fija un deducible de UF50+IVA.",
      "*Daños por faltas graves y/o negligencias no están cubiertas por el seguro. ",
    ],
  },
  {
    title: "¿Existen Cargos por Combustible?",
    texts: [
      "El vehículo se entrega con el estanque de combustible lleno y debe ser devuelto en las mismas condiciones. De lo contrario tendrá un recargo adicional por reabastecimiento de combustible.",
    ],
  },
  {
    title: "¿Puedo devolver el vehículo en otra ciudad?",
    texts: [
      "Solo se puede devolver en Santiago de chile, en las Sucursales de DITEC Car Rental, con previa confirmación.",
    ],
  },
];
