export const title = "FLOTA";
export const priceText = "Precio: ";
export const timesText = " por ";
export const dayText = " días";
export const doorsText = "PUERTAS";
export const capacityText = "CAPACIDAD: ";
export const peopleText = "PERSONAS";
export const selectText = "SELECCIONAR";
export const moreInfoText = "Más información";
export const totalPriceText = "Precio Total: ";
