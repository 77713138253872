import React, { ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Row, Card, Col } from "antd";
import { bindActionCreators } from "redux";
import emailRegExp from "../../../utils/validations/emailRegExp";
import "./newsletter.scss";
import "antd/dist/antd.css";
import * as homeInteractors from "../../../store/interactors/homeInteractors";
import Button from "../../generalUI/Button";
import { HomeState } from "../../../store/types/homeTypes";
import { AppDispatch, RootState } from "../../../store";
import { successModal } from "../../generalUI/FeedbackModal/FeedbackModal";
import {
  newsletterPlaceholder,
  newsletterTitle,
  newsletterButton,
  newsletterInvalid,
  newsletterSuccess,
} from "../../../assets/texts/home";

interface StateProps {
  homeState: HomeState;
}

interface DispatchProps {
  postNewsletterInteractor: typeof homeInteractors.postNewsletterInteractor;
  resetPostNewsletterStatusInteractor: typeof homeInteractors.resetPostNewsletterStatusInteractor;
}

interface Props extends StateProps, DispatchProps {}

const Newsletter = (props: Props): ReactElement => {
  const {
    homeState,
    postNewsletterInteractor,
    resetPostNewsletterStatusInteractor,
  } = props;
  const { postNewsletterStatus } = homeState;
  const { success, loading } = postNewsletterStatus;
  const [form] = Form.useForm();
  useEffect(() => {
    if (success) {
      successModal(newsletterSuccess.title, newsletterSuccess.text);
      resetPostNewsletterStatusInteractor();
    }
  }, [success]);

  return (
    <Card hoverable className="NewsletterForm">
      <Form
        form={form}
        layout="inline"
        onFinish={(values: Record<string, unknown>) => {
          form.resetFields();
          return postNewsletterInteractor(values);
        }}
      >
        <Row className="inputRow">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 13 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
            className="col"
          >
            <h1 className="title">{newsletterTitle}</h1>
          </Col>

          <Col
            xs={{ span: 15 }}
            sm={{ span: 18 }}
            md={{ span: 18 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
            className="col"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: newsletterPlaceholder },
                {
                  message: newsletterInvalid,
                  pattern: emailRegExp(),
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input placeholder={newsletterPlaceholder} />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 9 }}
            sm={{ span: 6 }}
            md={{ span: 6 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xxl={{ span: 3 }}
            className="col"
          >
            <Form.Item>
              <Button htmlType="submit" loading={loading} color="blackButton">
                {newsletterButton}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    homeState: state.home,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...homeInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
