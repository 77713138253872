import { AxiosResponse } from "axios";
import { axiosBaseInstance } from "./base";
import * as mappers from "./inwardOutwardMappers/subsidiaries";
import * as inwardTypes from "./inwardTypes/subsidiaries";
import * as outwardTypes from "./outwardTypes/subsidiaries";

// eslint-disable-next-line import/prefer-default-export
export const getSubsidiaries = async (): Promise<outwardTypes.Subsidiaries> => {
  return axiosBaseInstance({
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: "/subsidiaries",
  })
    .then((response: AxiosResponse<inwardTypes.Subsidiaries>) => {
      return mappers.subsidiariesMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};
