import React, { ReactElement } from "react";
import { Collapse } from "antd";
import styles from "./faq.module.scss";
import { title, content } from "../../assets/texts/faq";
import Container from "../../components/generalUI/Container";
import Title from "../../components/generalUI/Title";

const { Panel } = Collapse;

const Faq = (): ReactElement => {
  return (
    <Container>
      <Title centered>{title}</Title>
      <Collapse bordered={false} className={styles.collapse}>
        {content.map((item) => {
          return (
            <Panel header={item.title} key={item.title}>
              {item.texts.map((text) => {
                return <p key={text}>{text}</p>;
              })}
            </Panel>
          );
        })}
      </Collapse>
    </Container>
  );
};

export default Faq;
