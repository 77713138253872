import { SearchOutlined } from "@ant-design/icons";
import { Form } from "antd";
import React, { ReactElement } from "react";
import Button from "../../../generalUI/Button";

interface Props {
  message: string;
}

const ButtonFormItem = (props: Props): ReactElement => {
  const { message } = props;
  return (
    <Form.Item>
      <Button htmlType="submit" color="goldButton" icon={<SearchOutlined />}>
        {message}
      </Button>
    </Form.Item>
  );
};

export default ButtonFormItem;
