import React, { ReactElement } from "react";
import {
  FacebookOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import "./SocialMedia.scss";

const SocialMedia = (): ReactElement => {
  return (
    <div className="stickyContainer">
      <div className="socialContainer">
        <a href="https://wa.me/56945994394" target="_blank" rel="noreferrer">
          <WhatsAppOutlined className="socialIcon" />
        </a>
      </div>
      <div className="socialContainer">
        <a
          href="https://www.instagram.com/ditec.car.rental/"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramOutlined className="socialIcon" />
        </a>
      </div>
      <div className="socialContainer">
        <a
          href="https://www.facebook.com/DitecCarRental"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookOutlined className="socialIcon" />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
