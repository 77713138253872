import { AxiosResponse } from "axios";
import { axiosBaseInstance } from "./base";
import * as mappers from "./inwardOutwardMappers/rent";
import * as inwardTypes from "./inwardTypes/rent";
import * as outwardTypes from "./outwardTypes/rent";

export const getHolidays = async (): Promise<outwardTypes.Holidays> => {
  return axiosBaseInstance({
    headers: { "Content-Type": "application/json" },
    method: "get",
    url: `/holidays`,
  })
    .then((response: AxiosResponse<inwardTypes.Holidays>) => {
      return mappers.holidaysMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const getSearchResults = async (
  searchResultsFields: inwardTypes.GetSearchResultsFields
): Promise<outwardTypes.SearchResults> => {
  return axiosBaseInstance({
    headers: { "Content-Type": "application/json" },
    method: "get",
    url: `/car_search?brand=${searchResultsFields.brand}&family=${searchResultsFields.family}&reservation_start_date=${searchResultsFields.initialDate}&reservation_end_date=${searchResultsFields.finalDate}`,
  })
    .then((response: AxiosResponse<inwardTypes.SearchResults>) => {
      return mappers.searchResultsMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const getReservationResult = async (
  reservationResultFields: inwardTypes.GetReservationResultFields
): Promise<outwardTypes.ReservationResult> => {
  return axiosBaseInstance({
    headers: { "Content-Type": "application/json" },
    method: "get",
    url: `/transaction_info/${reservationResultFields.transaction}`,
  })
    .then((response: AxiosResponse<inwardTypes.ReservationResult>) => {
      return mappers.reservationResultMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const postTransaction = async (
  transactionFields: inwardTypes.PostTransactionFields
): Promise<outwardTypes.Transaction> => {
  return axiosBaseInstance({
    headers: { "Content-Type": "application/json" },
    method: "post",
    url: `/transaction`,
    data: transactionFields,
  })
    .then((response: AxiosResponse<inwardTypes.Transaction>) => {
      return mappers.transactionMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};
