import { Card, Descriptions } from "antd";
import React, { ReactElement } from "react";
import dateFormat from "utils/formats/dateFormat";
import { selectionSummary as texts } from "../../../../assets/texts/rent";

interface Props {
  columnsNumber: number;
  titleText: string;
  subsidiary: string;
  date: Date | undefined;
}

const EventDescription = (props: Props): ReactElement => {
  const { columnsNumber, titleText, subsidiary, date } = props;
  return (
    <Card type="inner" title={titleText}>
      <Descriptions column={columnsNumber}>
        <Descriptions.Item label={texts.subsidiaryLabel}>
          {subsidiary}
        </Descriptions.Item>
        <Descriptions.Item label={texts.dateLabel}>
          {dateFormat(date)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default EventDescription;
