import React, { ReactElement, useEffect } from "react";
import { Carousel } from "antd";
import "./carouselBanner.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "antd/dist/antd.css";
import * as homeInteractors from "../../../store/interactors/homeInteractors";
import { HomeState } from "../../../store/types/homeTypes";
import { AppDispatch, RootState } from "../../../store";
import { Promotion } from "../../../services/outwardTypes/home";

interface StateProps {
  homeState: HomeState;
}

interface DispatchProps {
  getPromotionsInteractor: typeof homeInteractors.getPromotionsInteractor;
}

interface Props extends StateProps, DispatchProps {}

const CarouselBanner = (props: Props): ReactElement => {
  const { homeState, getPromotionsInteractor } = props;
  const { promotions } = homeState;
  useEffect(() => {
    getPromotionsInteractor();
  }, [getPromotionsInteractor]);
  if (promotions.length) {
    return (
      <Carousel className="carousel" autoplay>
        {promotions.map((promotion: Promotion): JSX.Element => {
          return (
            <div key={promotion.url} className="carouselContent">
              <a href="#reservation">
                <img
                  src={promotion.url}
                  alt="Promoción"
                  className="carouselImage"
                />
              </a>
            </div>
          );
        })}
      </Carousel>
    );
  }

  return <div />;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    homeState: state.home,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...homeInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarouselBanner);
