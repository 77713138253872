import {
  CaseReducer,
  Draft,
  PayloadAction,
  SliceCaseReducers,
} from "@reduxjs/toolkit";
import { BaseRequestStatus } from "../types/base";

export interface Action {
  payload: unknown;
  type: string;
}

export const baseRequestInitialStatus = (
  requestName: string
): { [key: string]: BaseRequestStatus } => {
  return {
    [`${requestName}Status`]: { loading: false, success: false, error: false },
  };
};

function baseSuccessReducer<T>(
  requestName: string,
  resourceName: string
): CaseReducer<T, PayloadAction<{ [key: string]: unknown }>> {
  return (
    state: Draft<T>,
    action: PayloadAction<{ [key: string]: unknown }>
  ) => {
    return {
      ...state,
      [resourceName]: action.payload,
      [`${requestName}Status`]: {
        loading: false,
        success: true,
        error: false,
      },
    };
  };
}

function baseLoadingReducer<T>(
  requestName: string
): CaseReducer<T, PayloadAction<void>> {
  return (state: Draft<T>) => {
    return {
      ...state,
      [`${requestName}Status`]: { loading: true, success: false, error: false },
    };
  };
}

function baseErrorReducer<T>(
  requestName: string
): CaseReducer<T, PayloadAction<void>> {
  return (state: Draft<T>, action: PayloadAction<boolean | unknown>) => {
    return {
      ...state,
      [`${requestName}Status`]: {
        loading: false,
        success: false,
        error: action.payload || true,
      },
    };
  };
}

function baseResetReducer<T>(
  requestName: string
): CaseReducer<T, PayloadAction<void>> {
  return (state: Draft<T>) => {
    return {
      ...state,
      [`${requestName}Status`]: baseRequestInitialStatus(requestName),
    };
  };
}

export function baseRequestStatusReducers<T>(
  requestName: string,
  resourceName: string | null,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  successReducer = baseSuccessReducer<T>(requestName, resourceName!),
  loadingReducer = baseLoadingReducer<T>(requestName),
  errorReducer = baseErrorReducer<T>(requestName),
  resetReducer = baseResetReducer<T>(requestName)
): SliceCaseReducers<T> {
  const requestNameFirstCapital =
    requestName[0].toUpperCase() + requestName.slice(1);
  return {
    [`loading${requestNameFirstCapital}`]: loadingReducer,
    [`success${requestNameFirstCapital}`]: successReducer,
    [`error${requestNameFirstCapital}`]: errorReducer,
    [`reset${requestNameFirstCapital}`]: resetReducer,
  };
}
