import * as brandsService from "../../services/brandsService";
import { brandsActions } from "../slices/brandsSlice";
import {
  buildInteractorDirectActionNoParams,
  buildInteractorStaticNoParams,
} from "./base";

export const getBrandsInteractor = buildInteractorStaticNoParams(
  brandsActions.loadingGetBrands,
  brandsActions.successGetBrands,
  brandsActions.errorGetBrands,
  brandsService.getBrands,
  "brands",
  "brands"
);

export const resetGetBrandsStatusInteractor =
  buildInteractorDirectActionNoParams(brandsActions.resetGetBrands);

export const getBrandsFamiliesInteractor = buildInteractorStaticNoParams(
  brandsActions.loadingGetBrandsFamilies,
  brandsActions.successGetBrandsFamilies,
  brandsActions.errorGetBrandsFamilies,
  brandsService.getBrandsFamilies,
  "brands",
  "brandsFamilies"
);

export const resetGetBrandsFamiliesStatusInteractor =
  buildInteractorDirectActionNoParams(brandsActions.resetGetBrandsFamilies);

export const getBrandsCarModelsInteractor = buildInteractorStaticNoParams(
  brandsActions.loadingGetBrandsCarModels,
  brandsActions.successGetBrandsCarModels,
  brandsActions.errorGetBrandsCarModels,
  brandsService.getBrandsCarModels,
  "brands",
  "brandsCarModels"
);

export const resetGetBrandsCarModelsStatusInteractor =
  buildInteractorDirectActionNoParams(brandsActions.resetGetBrandsCarModels);
