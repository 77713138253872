import { AxiosResponse } from "axios";
import { axiosBaseInstance } from "./base";
import * as mappers from "./inwardOutwardMappers/home";
import * as inwardTypes from "./inwardTypes/home";
import * as outwardTypes from "./outwardTypes/home";

export const getPromotions = async (): Promise<outwardTypes.Promotions> => {
  return axiosBaseInstance({
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: "/promotion",
  })
    .then((response: AxiosResponse<inwardTypes.Promotions>) => {
      return mappers.promotionsMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const postNewsletter = async (
  newsslettterFields: inwardTypes.PostNewsletterFields
): Promise<unknown> => {
  return axiosBaseInstance({
    headers: { "Content-Type": "application/json" },
    method: "post",
    url: "/inform_new_newsletter",
    data: {
      email: newsslettterFields.email,
    },
  })
    .then((response: AxiosResponse<Record<string, unknown>>) => {
      if (response?.data?.status === 200) {
        return response?.data?.data;
      }
      const errorMessage = response?.data?.errors as string;
      throw new Error(errorMessage);
    })
    .catch((error: Error) => {
      throw error;
    });
};

export const getAnnouncement = async (): Promise<outwardTypes.Announcement> => {
  return axiosBaseInstance({
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: "/announcement",
  })
    .then(
      (
        response: AxiosResponse<inwardTypes.Announcement>
      ): outwardTypes.Announcement => {
        return mappers.announcementMapper(response?.data);
      }
    )
    .catch((error: Error) => {
      throw error;
    });
};
