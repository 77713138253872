import React, { ReactElement } from "react";
import { Col, Row } from "antd";
import "antd/dist/antd.css";
import "./RentLayout.scss";
import useMaxBreakpoint from "../../../utils/breakpoints/useMaxBreakpoint";
import { Breakpoints } from "../../../utils/breakpoints/breakpoints";

interface Props {
  siderRight?: JSX.Element;
  children?: JSX.Element;
  siderLeft?: JSX.Element;
}

const RentLayout = ({
  children,
  siderRight,
  siderLeft,
}: Props): ReactElement => {
  const maxBreakpoint = useMaxBreakpoint();
  const sidersNumber = [siderLeft, siderRight].reduce(
    (prev, curr) => (curr ? prev + 1 : prev),
    0
  );
  let siderColSpan = 8;
  if (maxBreakpoint >= Breakpoints.LG) {
    siderColSpan = 7;
  }
  if (maxBreakpoint >= Breakpoints.XL) {
    siderColSpan = 6;
  }
  if (maxBreakpoint >= Breakpoints.XXL) {
    siderColSpan = 6;
  }

  const contentClassName =
    siderRight || siderLeft ? "content" : "content noSider";

  return (
    <Row justify="space-between" className="rentLayout">
      {siderLeft && (
        <Col className="sider" span={siderColSpan}>
          {siderLeft}
        </Col>
      )}
      {children && (
        <Col
          className={contentClassName}
          span={24 - sidersNumber * siderColSpan}
        >
          {children}
        </Col>
      )}
      {siderRight && (
        <Col className="sider" span={siderColSpan}>
          {siderRight}
        </Col>
      )}
    </Row>
  );
};
RentLayout.defaultProps = {
  siderRight: null,
  siderLeft: null,
  children: null,
};

export default RentLayout;
