import React, { ReactElement } from "react";
import { connect } from "react-redux";
import priceFormat from "utils/formats/priceFormat";
import { PersonalInformation } from "services/outwardTypes/rent";
import { bindActionCreators } from "redux";
import PriceSummary from "../PriceSummary";
import SelectionSummary from "../SelectionSummary";
import PersonalInfoForm from "./PersonalInfoForm";
import useMaxBreakpoint from "../../../utils/breakpoints/useMaxBreakpoint";
import { Breakpoints } from "../../../utils/breakpoints/breakpoints";
import { personalInfoStep as texts } from "../../../assets/texts/rent";
import { RentState } from "../../../store/types/rentTypes";
import { AppDispatch, RootState } from "../../../store";
import { SubsidiariesState } from "../../../store/types/subsidiariesTypes";
import subsidiaryName from "../../../utils/formats/subsidiaryName";
import * as rentInteractors from "../../../store/interactors/rentInteractors";
import ResponsiveStepContent, { Element } from "../ResponsiveStepContent";

interface StateProps {
  rentState: RentState;
  subsidiariesState: SubsidiariesState;
}
interface DispatchProps {
  setCurrentStepIndexInteractor: typeof rentInteractors.setCurrentStepIndexInteractor;
  setPersonalInformationInteractor: typeof rentInteractors.setPersonalInformationInteractor;
}

interface Props extends StateProps, DispatchProps {}

const RentPersonalInfo = (props: Props): ReactElement => {
  const {
    rentState,
    subsidiariesState,
    setCurrentStepIndexInteractor,
    setPersonalInformationInteractor,
  } = props;
  const { carSelection, datesInfo, pickUp, dropOff, personalInformation } =
    rentState;
  const { subsidiaries } = subsidiariesState;
  const nextStep = (values: PersonalInformation) => {
    setPersonalInformationInteractor(values);
    setCurrentStepIndexInteractor(2);
  };
  const maxBreakpoint = useMaxBreakpoint();
  let columnsSpan = 24;
  let bodyAndSider = false;
  if (maxBreakpoint >= Breakpoints.SM) {
    columnsSpan = 12;
  }
  if (maxBreakpoint >= Breakpoints.LG) {
    columnsSpan = 24;
    bodyAndSider = true;
  }
  const columnsNumber = 24 / columnsSpan;
  const totalPriceText = priceFormat(carSelection?.priceInfo.totalPrice);
  const selectionSummary = carSelection && (
    <SelectionSummary
      columnsSpan={columnsSpan}
      model={carSelection}
      dates={datesInfo}
      pickUp={subsidiaryName(subsidiaries, pickUp)}
      dropOff={subsidiaryName(subsidiaries, dropOff)}
    />
  );
  const priceSummary = carSelection && (
    <PriceSummary
      columnsNumber={columnsNumber}
      priceInfo={carSelection?.priceInfo}
      duration={datesInfo?.durationDays}
    />
  );
  const personalInfoForm = (
    <PersonalInfoForm
      columnsSpan={columnsSpan}
      labelProportion="default"
      nextStep={nextStep}
      personalInformation={personalInformation}
    />
  );
  const elements = carSelection && [
    {
      key: "0",
      header: texts.selectionHeader,
      defaultActive: true,
      siderable: false,
      content: selectionSummary,
    },
    {
      key: "1",
      header: texts.paymentHeader + totalPriceText,
      defaultActive: false,
      siderable: false,
      content: priceSummary,
    },
    {
      key: "2",
      header: texts.personalInfoHeader,
      defaultActive: true,
      siderable: true,
      content: personalInfoForm,
    },
  ];
  return (
    <ResponsiveStepContent
      bodyAndSider={bodyAndSider}
      elements={elements as Element[]}
    />
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    rentState: state.rent,
    subsidiariesState: state.subsidiaries,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...rentInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RentPersonalInfo);
