import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, useLocation } from "react-router-dom"; // Import Route
import { PersistGate } from "redux-persist/integration/react";
import ReactGA from "react-ga"; // Import ReactGA
import Routes from "./routes";
import { store, persistor } from "./store";

// Initialize ReactGA with your Measurement ID
ReactGA.initialize("GTM-MJCKH4S");

const App = (): React.ReactElement => {
  const location = useLocation();

  useEffect(() => {
    // Track the initial page view when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location.pathname]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Route path="/" component={Routes} />
      </PersistGate>
    </Provider>
  );
};

export default App;
