import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col, Row } from "antd";
import { useParams } from "react-router-dom";
import { CaretRightFilled } from "@ant-design/icons";
import Price from "components/sharedComponents/Price";
import { TermsFile } from "assets/files";
import CarModelSlideshow from "../../components/carModelComponents/CarModelSlideshow";
import styles from "./carModel.module.scss";
import Container from "../../components/generalUI/Container";
import * as carModelsInteractors from "../../store/interactors/carModelsInteractors";
import * as brandsInteractors from "../../store/interactors/brandsInteractors";
import { CarModelsState } from "../../store/types/carModelsTypes";
import { BrandsState } from "../../store/types/brandsTypes";
import { AppDispatch, RootState } from "../../store";
import Divider from "../../components/generalUI/Divider";
import CarModelDetailsTable from "../../components/carModelComponents/CarModelDetailsTable";
import * as texts from "../../assets/texts/carModel";

interface StateProps {
  carModelsState: CarModelsState;
  brandsState: BrandsState;
}

interface DispatchProps {
  getCarModelInteractor: typeof carModelsInteractors.getCarModelInteractor;
  getBrandsInteractor: typeof brandsInteractors.getBrandsInteractor;
}

interface Props extends StateProps, DispatchProps {}

type Params = {
  id: string;
};

const CarModelDetails = (props: Props): ReactElement => {
  const {
    carModelsState,
    getCarModelInteractor,
    brandsState,
    getBrandsInteractor,
  } = props;
  const { id } = useParams<Params>();
  const { carModel } = carModelsState;
  const { brands } = brandsState;

  const [brandName, setBrandName] = useState("");

  useEffect(() => {
    getCarModelInteractor(id);
  }, [getCarModelInteractor]);

  useEffect(() => {
    if (brands && carModel) {
      if (
        carModel.brandId &&
        brands[carModel.brandId] &&
        brands[carModel.brandId]
      ) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setBrandName(brands[carModel.brandId].name!);
      } else {
        getBrandsInteractor();
      }
    }
  }, [getBrandsInteractor, brands, brandName]);

  const bullet = <CaretRightFilled style={{ marginRight: "1%" }} />;

  return carModel ? (
    <>
      <Row justify="center">
        <Col xs={24}>
          <CarModelSlideshow carModel={carModel} />
        </Col>
      </Row>
      <Row>
        <Container>
          <Row justify="center" align="middle" className={styles.marginTopL}>
            <Col xs={24} md={16}>
              <h1 className={styles.modelName}>{`${brandName} ${
                carModel.name as string
              }`}</h1>
            </Col>
          </Row>
          <Row justify="center" align="middle" className={styles.marginTopS}>
            <Col xs={24} md={16}>
              <p>
                {carModel.modelDescription
                  ? carModel.modelDescription.description
                  : ""}
              </p>
            </Col>
          </Row>

          <Row justify="center" align="middle" className={styles.marginTopBig}>
            <Col xs={24} md={16}>
              <h3>{texts.details}</h3>
              <Divider />
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            className={styles.marginTopSmall}
          >
            <Col xs={24} md={16}>
              <CarModelDetailsTable {...carModel} />
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            className={styles.marginTopSmall}
          >
            <Col xs={24} md={16}>
              {carModel.priceInfo ? (
                <Price
                  hasDiscount={carModel.priceInfo.hasDiscount}
                  priceText={texts.priceText}
                  price={carModel.priceInfo.price}
                  priceBeforeDiscountText={texts.priceText}
                  priceBeforeDiscount={carModel.priceInfo.priceBeforeDiscount}
                  slim
                  afterMessage={texts.includesIva}
                />
              ) : (
                ""
              )}
              <p>
                <a
                  href={TermsFile as string}
                  className={styles.termsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {texts.termsAndConditionsLink}
                </a>
              </p>
            </Col>
          </Row>
          <Row justify="center" align="middle" className={styles.marginTopBig}>
            <Col xs={24} md={16} className={styles.termsAndConditionsContainer}>
              <Row justify="center" align="middle">
                <Col xs={24} md={22} className={styles.termsAndConditions}>
                  <p>{texts.notIncluded}</p>
                  {bullet}
                  {texts.tag}
                  <br />
                  {bullet}
                  {texts.gas}
                  <br />
                  <p className={styles.smallLetter}>{texts.smallLetter}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  ) : (
    <></>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    carModelsState: state.carModels,
    brandsState: state.brands,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...carModelsInteractors }, dispatch),
  ...bindActionCreators({ ...brandsInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CarModelDetails);
