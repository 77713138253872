import { Card, Descriptions } from "antd";
import Price from "components/sharedComponents/Price";
import React, { ReactElement } from "react";
import { PriceInfo } from "services/outwardTypes/rent";
import { priceSummary as texts } from "../../../assets/texts/rent";

interface Props {
  columnsNumber: number;
  priceInfo: PriceInfo;
  duration: number | undefined;
  children?: JSX.Element;
}

const PriceSummary = (props: Props): ReactElement => {
  const { columnsNumber, priceInfo, duration, children } = props;
  return (
    <Card type="inner">
      <Descriptions column={columnsNumber}>
        <Descriptions.Item label={texts.dailyPriceLabel}>
          <Price
            hasDiscount={priceInfo.hasDiscount}
            price={priceInfo.dailyPrice}
            priceBeforeDiscount={priceInfo.dailyPriceBeforeDiscount}
            priceText=""
            priceBeforeDiscountText=""
            slim
          />
        </Descriptions.Item>
        <Descriptions.Item label={texts.durationDaysLabel}>
          {duration}
          {texts.daysLabel}
        </Descriptions.Item>
      </Descriptions>
      {children}
    </Card>
  );
};

PriceSummary.defaultProps = {
  children: null,
};

export default PriceSummary;
