import React, { ReactElement } from "react";
import { Col, Row } from "antd";
import styles from "./price.module.scss";
import priceFormat from "../../../utils/formats/priceFormat";

interface Props {
  hasDiscount: boolean | null | undefined;
  priceText: string;
  price: number | null | undefined;
  priceBeforeDiscountText?: string;
  priceBeforeDiscount?: number | null;
  slim?: boolean;
  afterMessage?: string;
}

const Price = (props: Props): ReactElement => {
  const {
    hasDiscount,
    afterMessage,
    priceText,
    price,
    priceBeforeDiscountText,
    priceBeforeDiscount,
    slim,
  } = props;
  const containerClassNames = [
    styles.prices,
    slim ? "" : styles.withMargin,
  ].join(" ");

  return (
    <Row className={containerClassNames}>
      {hasDiscount && (
        <Col span={24} className={styles.priceBeforeDiscountText}>
          {priceBeforeDiscountText}
          {priceFormat(priceBeforeDiscount)}
        </Col>
      )}
      <Col span={24} className={styles.priceText}>
        {[priceText, priceFormat(price), afterMessage].join(" ")}
      </Col>
    </Row>
  );
};

Price.defaultProps = {
  priceBeforeDiscountText: null,
  priceBeforeDiscount: null,
  slim: false,
  afterMessage: "",
};

export default Price;
