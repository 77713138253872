import { Form, Select } from "antd";
import React, { ReactElement } from "react";
import {
  BrandsFamilies,
  Family,
} from "../../../../services/outwardTypes/brands";

interface Props {
  families: BrandsFamilies;
  allOption: string;
  selectedBrand: string;
}

const FamilyFormItem = (props: Props): ReactElement => {
  const { families, allOption, selectedBrand } = props;
  const { Option } = Select;
  return (
    <Form.Item name="family">
      <Select>
        <Option value={-1}>{allOption}</Option>
        {families[selectedBrand]
          ? families[selectedBrand].map((family: Family): ReactElement => {
              return (
                <Option value={family.id} key={family.id}>
                  {family.name}
                </Option>
              );
            })
          : null}
      </Select>
    </Form.Item>
  );
};

export default FamilyFormItem;
