import React, { ReactElement, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Row, Card, Checkbox, Col } from "antd";
import { bindActionCreators } from "redux";
import "./reservationForm.scss";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import { Dayjs } from "dayjs";
import * as rentInteractors from "../../../store/interactors/rentInteractors";
import * as subsidiariesInteractors from "../../../store/interactors/subsidiariesInteractor";
import * as brandsInteractors from "../../../store/interactors/brandsInteractors";
import { SubsidiariesState } from "../../../store/types/subsidiariesTypes";
import { BrandsState } from "../../../store/types/brandsTypes";
import { RentState } from "../../../store/types/rentTypes";
import { AppDispatch, RootState } from "../../../store";
import * as texts from "../../../assets/texts/reservationForm";
import BrandFormItem from "./BrandFormItem";
import FamilyFormItem from "./FamilyFormItem";
import DatesFormItem from "./DatesFromItem";
import SubsidiaryFormItem from "./SubsidiaryFormItem";
import ButtonFormItem from "./ButtonFormItem";

export interface Reservation {
  brand: string;
  family: string;
  withdraw: number;
  delivery: number;
  dates: Array<Dayjs>;
}
interface StateProps {
  rentState: RentState;
  subsidiariesState: SubsidiariesState;
  brandsState: BrandsState;
}

interface DispatchProps {
  getBrandsInteractor: typeof brandsInteractors.getBrandsInteractor;
  getBrandsFamiliesInteractor: typeof brandsInteractors.getBrandsFamiliesInteractor;
  getHolidaysInteractor: typeof rentInteractors.getHolidaysInteractor;
  getSubsidiariesInteractor: typeof subsidiariesInteractors.getSubsidiariesInteractor;
  setPickUpInteractor: typeof rentInteractors.setPickUpInteractor;
  setDropOffInteractor: typeof rentInteractors.setDropOffInteractor;
  setRentUrlInteractor: typeof rentInteractors.setRentUrlInteractor;
  resetRentStateInteractor: typeof rentInteractors.resetRentStateInteractor;
}

interface Props extends StateProps, DispatchProps {
  currentReservation?: Reservation | undefined;
  customStyle: "sider" | "default";
}

const ReservationForm = (props: Props): ReactElement => {
  const {
    rentState,
    subsidiariesState,
    brandsState,
    getSubsidiariesInteractor,
    getBrandsInteractor,
    getBrandsFamiliesInteractor,
    getHolidaysInteractor,
    setDropOffInteractor,
    setPickUpInteractor,
    setRentUrlInteractor,
    resetRentStateInteractor,
    currentReservation,
    customStyle,
  } = props;
  const { holidays } = rentState;
  const { brandsFamilies, brands } = brandsState;
  const { subsidiaries } = subsidiariesState;
  const [form] = Form.useForm();
  const [checkbox, setCheckbox] = useState(true);
  const history = useHistory();
  const [selectedBrand, setSelectedBrand] = useState("");

  useEffect(() => {
    getSubsidiariesInteractor();
  }, [getSubsidiariesInteractor]);

  useEffect(() => {
    getBrandsInteractor();
  }, [getBrandsInteractor]);

  useEffect(() => {
    getBrandsFamiliesInteractor();
  }, [getBrandsFamiliesInteractor]);

  useEffect(() => {
    getHolidaysInteractor();
  }, [getHolidaysInteractor]);

  useEffect(() => {
    setTimeout(() => {
      if (currentReservation) {
        form.setFieldsValue(currentReservation);
        setSelectedBrand(currentReservation.brand);
        setCheckbox(
          currentReservation.delivery === currentReservation.withdraw
        );
      } else {
        form.setFieldsValue({ brand: "-1", family: -1 });
      }
    }, 1000);
  }, [form, currentReservation]);

  const onFinish = (values: Reservation) => {
    resetRentStateInteractor();
    const { withdraw, dates, brand, family } = values;
    const delivery = values.delivery || withdraw;
    const [initialDate, finalDate] = dates.map((date: Dayjs): string =>
      date.format("YYYY-MM-DD")
    );
    setPickUpInteractor(withdraw);
    setDropOffInteractor(delivery);
    const url = `/rent/${brand}/${family}/${withdraw}/${delivery}/${initialDate}/${finalDate}`;
    setRentUrlInteractor(url);
    history.push(url);
  };
  const centerStyle: React.CSSProperties = {
    textAlign: "center",
  };
  const hover = customStyle === "default";
  const bordered = customStyle !== "sider";
  return (
    <div className="ReservationForm">
      <Card hoverable={hover} className={customStyle} bordered={bordered}>
        <Form form={form} onFinish={onFinish}>
          <h1 className="title">{texts.title}</h1>
          <Row
            style={{
              maxWidth: "360px",
              justifyContent: "space-around",
              margin: "auto",
            }}
          >
            <Col style={centerStyle} span={24}>
              <h2 className="subtitle">{texts.brandLabel}</h2>
              <BrandFormItem
                brands={brands}
                allOption={texts.allOption}
                form={form}
                setSelectedBrand={setSelectedBrand}
              />
            </Col>
            <Col style={centerStyle} span={24}>
              <h2 className="subtitle">{texts.modelLabel}</h2>
              <FamilyFormItem
                families={brandsFamilies}
                allOption={texts.allOption}
                selectedBrand={selectedBrand}
              />
            </Col>
            <Col style={centerStyle} span={24}>
              <h2 className="subtitle">{texts.withdrawLabel}</h2>
              <Checkbox
                checked={checkbox}
                onChange={(): void => setCheckbox(!checkbox)}
              >
                <p className="checktitle"> {texts.deliveryLabel}</p>
              </Checkbox>
              <Row>
                <Col span={checkbox ? 24 : 12}>
                  <SubsidiaryFormItem
                    name="withdraw"
                    subsidiaries={subsidiaries}
                    message={texts.subsidiaryMessage}
                    placeholder={texts.withdrawPlaceholder}
                  />
                </Col>
                {checkbox ? null : (
                  <Col span={12}>
                    <SubsidiaryFormItem
                      name="delivery"
                      subsidiaries={subsidiaries}
                      message={texts.subsidiaryMessage}
                      placeholder={texts.deliveryPlaceholder}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col style={centerStyle} span={24}>
              <h2 className="subtitle">{texts.datesLabel}</h2>
              <DatesFormItem
                holidays={holidays}
                message={texts.datesMessage}
                placeholder={texts.datesPlaceholder}
                form={form}
              />
            </Col>
            <Col style={{ maxHeight: "35px" }} span={24}>
              <ButtonFormItem message={texts.search} />
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

ReservationForm.defaultProps = {
  currentReservation: undefined,
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    rentState: state.rent,
    subsidiariesState: state.subsidiaries,
    brandsState: state.brands,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...rentInteractors }, dispatch),
  ...bindActionCreators({ ...subsidiariesInteractors }, dispatch),
  ...bindActionCreators({ ...brandsInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReservationForm);
