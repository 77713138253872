import React, { ReactElement, useState } from "react";
import { Drawer, Button, Row, Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import NavMenu from "./NavMenu";
import "antd/dist/antd.css";
import { Logo } from "../../../../assets/images";
import useMaxBreakpoint from "../../../../utils/breakpoints/useMaxBreakpoint";
import { Breakpoints } from "../../../../utils/breakpoints/breakpoints";
import "./navbar.scss";

const NavBar = (): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const maxBreakpoint = useMaxBreakpoint();

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };
  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };
  const fullHeightCentered: React.CSSProperties = {
    height: "100%",
    textAlign: "center",
  };
  return (
    <div>
      {maxBreakpoint > Breakpoints.MD ? (
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col
            flex={3}
            style={{
              height: "100%",
              textAlign: "left",
            }}
          >
            <div className="logo">
              <a href="/">
                <img alt="Logo" src={Logo} className="logo-nav" />
              </a>
            </div>
          </Col>
          <Col flex="end" style={{ alignSelf: "right" }}>
            <Row justify="end">
              <Col flex="end">
                <NavMenu mode="horizontal" onClose={onCloseDrawer} />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Drawer
            title="DITEC CAR RENTAL"
            placement="left"
            closable={false}
            onClose={onCloseDrawer}
            visible={isDrawerOpen}
          >
            <NavMenu mode="vertical" onClose={onCloseDrawer} />
          </Drawer>
          <Col flex={1} style={fullHeightCentered}>
            <div>
              <Button type="primary" onClick={showDrawer}>
                <MenuOutlined className="icon" />
              </Button>
            </div>
          </Col>
          <Col flex={4} style={fullHeightCentered}>
            <div className="logo">
              <a href="/">
                <img alt="Logo" src={Logo} className="logo-nav" />
              </a>
            </div>
          </Col>
          <Col flex={1} style={fullHeightCentered} />
        </Row>
      )}
    </div>
  );
};
export default NavBar;
