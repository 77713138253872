import * as inwardTypes from "../inwardTypes/carModels";
import * as outwardTypes from "../outwardTypes/carModels";

const modelDescriptionMapper = (
  inwardModelDescription: inwardTypes.CarModelDescription
): outwardTypes.CarModelDescription => ({
  description: inwardModelDescription.big_detail,
  doors: inwardModelDescription.doors,
  airConditioning: inwardModelDescription.air_conditioning,
  transmission: inwardModelDescription.transmission,
  capacity: inwardModelDescription.capacity,
});

const priceInfoMapper = (
  inwardPriceInfo: inwardTypes.PriceInfo
): outwardTypes.PriceInfo => ({
  price: inwardPriceInfo.price,
  hasDiscount: inwardPriceInfo.has_discount,
  discount: inwardPriceInfo.discount,
  priceBeforeDiscount: inwardPriceInfo.price_before_discount,
});

// eslint-disable-next-line import/prefer-default-export
export const carModelsMapper = (
  inwardCarModel: inwardTypes.CarModel
): outwardTypes.CarModel => {
  return {
    id: inwardCarModel.car_model_id,
    name: inwardCarModel.name,
    mainImageUrl: inwardCarModel.main_image_url,
    brandId: inwardCarModel.brand_id,
    modelDescription: modelDescriptionMapper(inwardCarModel.model_description),
    priceInfo: priceInfoMapper(inwardCarModel.price_info),
    imagesUrls: inwardCarModel.images_urls,
  };
};
