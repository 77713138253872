import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainLayout from "../components/generalUI/MainLayout";
import Home from "../views/Home";
import Faq from "../views/Faq";
import CarModel from "../views/CarModel";
import Subsidiaries from "../views/Subsidiaries";
import Fleet from "../views/Fleet";
import Rent from "../views/Rent";
import Voucher from "../views/Voucher";

const Routes = (): React.ReactElement => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <MainLayout>
            <Home />
          </MainLayout>
        )}
      />
      <Route
        exact
        path="/faq"
        render={() => (
          <MainLayout>
            <Faq />
          </MainLayout>
        )}
      />
      <Route
        exact
        path="/car-model/:id"
        render={() => (
          <MainLayout>
            <CarModel />
          </MainLayout>
        )}
      />
      <Route
        exact
        path="/subsidiaries"
        render={() => (
          <MainLayout>
            <Subsidiaries />
          </MainLayout>
        )}
      />
      <Route
        exact
        path="/fleet"
        render={() => (
          <MainLayout>
            <Fleet />
          </MainLayout>
        )}
      />
      <Route
        exact
        path="/rent/:brand/:family/:withdraw/:delivery/:initialDate/:finalDate"
        render={() => (
          <MainLayout>
            <Rent />
          </MainLayout>
        )}
      />
      <Route
        exact
        path="/voucher/:transaction"
        render={() => (
          <MainLayout>
            <Voucher />
          </MainLayout>
        )}
      />
      <Redirect exact path="*" to="/" />
    </Switch>
  );
};

export default Routes;
