import { AxiosResponse } from "axios";
import { axiosBaseInstance } from "./base";
import * as mappers from "./inwardOutwardMappers/carModels";
import * as inwardTypes from "./inwardTypes/carModels";
import * as outwardTypes from "./outwardTypes/carModels";

// eslint-disable-next-line import/prefer-default-export
export const getCarModel = async (
  id: number
): Promise<outwardTypes.CarModel> => {
  return axiosBaseInstance({
    headers: {
      "Content-Type": "application/json",
    },
    method: "get",
    url: `/car_model_info/${id}`,
  })
    .then((response: AxiosResponse<inwardTypes.CarModel>) => {
      return mappers.carModelsMapper(response?.data);
    })
    .catch((error: Error) => {
      throw error;
    });
};
