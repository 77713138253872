import React, { ReactElement } from "react";
import { Row } from "antd";
import styles from "./home.module.scss";
import "antd/dist/antd.css";
import {
  GridImage,
  ReservationBackgroundSmall,
  ReservationBackgroundLarge,
  ReservationBackgroundXLarge,
} from "../../assets/images";
import Newsletter from "../../components/homeComponents/Newsletter";
import ReservationForm from "../../components/sharedComponents/ReservationForm";
import { Breakpoints } from "../../utils/breakpoints/breakpoints";
import useMaxBreakpoint from "../../utils/breakpoints/useMaxBreakpoint";
import AboutUs from "../../components/homeComponents/AboutUs";
import Catalogue from "../../components/homeComponents/Catalogue";
import CarouselBanner from "../../components/homeComponents/CarouselBanner";
import AnnouncementModal from "../../components/homeComponents/AnnouncementModal";

const Home = (): ReactElement => {
  const maxBreakpoint = useMaxBreakpoint();
  let ReservationBackground = ReservationBackgroundSmall;
  if (maxBreakpoint > Breakpoints.SM && maxBreakpoint < Breakpoints.XL) {
    ReservationBackground = ReservationBackgroundLarge;
  } else if (maxBreakpoint >= Breakpoints.XL) {
    ReservationBackground = ReservationBackgroundXLarge;
  }
  const fullWidth = { width: "100%" };
  return (
    <div className={styles.app}>
      <Row
        id="reservation"
        className={styles.reservationContent}
        justify="space-between"
        align="middle"
        style={fullWidth}
      >
        <div className={styles.reservationGrid}>
          <img
            src={ReservationBackground}
            alt="Reservación"
            className={styles.reservationImage}
          />
          <div className={styles.reservationCard}>
            <ReservationForm customStyle="default" />
          </div>
        </div>
      </Row>
      <AnnouncementModal />
      <div id="promotions">
        <CarouselBanner />
      </div>
      <Row
        id="about-us"
        justify="space-between"
        align="middle"
        className={styles.aboutUSContent}
      >
        <AboutUs />
      </Row>
      <Row
        id="brands"
        className={styles.brandsContent}
        justify="space-between"
        align="middle"
        style={fullWidth}
      >
        <img src={GridImage} alt="Modelos" className={styles.brandsImage} />
      </Row>
      <Row
        id="catalogue"
        className={styles.catalogueContent}
        align="middle"
        style={fullWidth}
      >
        <Catalogue />
      </Row>
      <Row
        id="newsletter"
        justify="space-between"
        align="middle"
        className={styles.newsletterContent}
      >
        <Newsletter />
      </Row>
    </div>
  );
};

export default Home;
