import dayjs from "dayjs";
import React, { ReactElement, useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Drawer, Row, Spin } from "antd";
import { FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as rentInteractors from "../../../store/interactors/rentInteractors";
import Button from "../../generalUI/Button";
import AvailableCarModelCard from "../AvailableCarModelCard";
import ReservationForm, {
  Reservation,
} from "../../sharedComponents/ReservationForm";
import useMaxBreakpoint from "../../../utils/breakpoints/useMaxBreakpoint";
import { Breakpoints } from "../../../utils/breakpoints/breakpoints";
import "./step1.scss";
import { AppDispatch, RootState } from "../../../store";
import { RentState } from "../../../store/types/rentTypes";
import * as texts from "../../../assets/texts/rent";
import RentLayout from "../RentLayout";
import { Car } from "../../../services/outwardTypes/rent";

interface Params {
  brand: string;
  family: string;
  withdraw: string;
  delivery: string;
  initialDate: string;
  finalDate: string;
}
interface StateProps {
  rentState: RentState;
}

interface DispatchProps {
  getSearchResultsInteractor: typeof rentInteractors.getSearchResultsInteractor;
  resetGetSearchResultsStatusInteractor: typeof rentInteractors.resetGetSearchResultsStatusInteractor;
  setCurrentStepIndexInteractor: typeof rentInteractors.setCurrentStepIndexInteractor;
  setCarSelectionInteractor: typeof rentInteractors.setCarSelectionInteractor;
}

interface Props extends StateProps, DispatchProps {}

const RentCarSearch = (props: Props): ReactElement => {
  const {
    rentState,
    getSearchResultsInteractor,
    resetGetSearchResultsStatusInteractor,
    setCurrentStepIndexInteractor,
    setCarSelectionInteractor,
  } = props;
  const { availableCars, datesInfo, getSearchResultsStatus } = rentState;
  const { success, loading } = getSearchResultsStatus;
  const { brand, family, withdraw, delivery, initialDate, finalDate } =
    useParams<Params>();

  dayjs.extend(customParseFormat);
  const currentReservation: Reservation = {
    brand,
    family,
    withdraw: parseInt(withdraw, 10),
    delivery: parseInt(delivery, 10),
    dates: [dayjs(initialDate, "YYYY-MM-DD"), dayjs(finalDate, "YYYY-MM-DD")],
  };
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const maxBreakpoint = useMaxBreakpoint();

  const showFilter = () => {
    setIsFilterOpen(true);
  };
  const onCloseFilter = () => {
    setIsFilterOpen(false);
  };
  const reservationForm: JSX.Element = (
    <ReservationForm
      currentReservation={currentReservation}
      customStyle="sider"
    />
  );

  const nextStep = (car: Car) => {
    setCurrentStepIndexInteractor(1);
    setCarSelectionInteractor(car);
  };

  useEffect(() => {
    getSearchResultsInteractor({ brand, family, initialDate, finalDate });
  }, [getSearchResultsInteractor, brand, family, initialDate, finalDate]);

  useEffect(() => {
    if (success) {
      resetGetSearchResultsStatusInteractor();
    }
  }, [success]);

  const cards = (
    <div>
      {availableCars.length ? (
        availableCars.map((rentCar: Car) => (
          <Row key={rentCar.id}>
            <AvailableCarModelCard
              model={rentCar}
              datesInfo={datesInfo}
              nextStep={nextStep}
            />
          </Row>
        ))
      ) : (
        <p className="noResultsCarModelCard">{texts.noResults}</p>
      )}
    </div>
  );

  return (
    <div className="carSearch">
      {maxBreakpoint > Breakpoints.MD ? (
        <RentLayout
          siderLeft={
            <div className="reservationContainer">{reservationForm}</div>
          }
        >
          {loading ? (
            <Spin indicator={<LoadingOutlined className="Loader" spin />} />
          ) : (
            cards
          )}
        </RentLayout>
      ) : (
        <RentLayout>
          <div>
            <div className="carSearchFilterButton">
              <Button
                onClick={showFilter}
                color="goldButton"
                loading={loading}
                icon={<FilterOutlined />}
              >
                {texts.editSearchButon}
              </Button>
            </div>
            {cards}
            <Drawer
              placement="right"
              closable={false}
              onClose={onCloseFilter}
              visible={isFilterOpen}
              width="auto"
              className="rentDrawer"
            >
              {reservationForm}
            </Drawer>
          </div>
        </RentLayout>
      )}
    </div>
  );
};
const mapStateToProps = (state: RootState): StateProps => {
  return {
    rentState: state.rent,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...rentInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RentCarSearch);
