import React, { ReactElement, useEffect, useState } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./announcementModal.scss";
import "antd/dist/antd.css";
import * as homeInteractors from "../../../store/interactors/homeInteractors";
import { HomeState } from "../../../store/types/homeTypes";
import { AppDispatch, RootState } from "../../../store";
import Image from "../../generalUI/ImageWithLoader";

interface StateProps {
  homeState: HomeState;
}

interface DispatchProps {
  getAnnouncementInteractor: typeof homeInteractors.getAnnouncementInteractor;
}

interface Props extends StateProps, DispatchProps {}

const AnnouncementModal = (props: Props): ReactElement => {
  const { homeState, getAnnouncementInteractor } = props;
  const announcementUrl = (homeState?.announcement?.url as string) || null;
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    getAnnouncementInteractor();
  }, [getAnnouncementInteractor]);
  if (announcementUrl) {
    return (
      <Modal
        centered
        visible={visible}
        width="auto"
        footer={[]}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: "0%" }}
      >
        <Image src={announcementUrl} width="100%" />
      </Modal>
    );
  }

  return <div />;
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    homeState: state.home,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  ...bindActionCreators({ ...homeInteractors }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementModal);
