import React, { ReactElement } from "react";
import styles from "./title.module.scss";

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
}

const Title = ({
  centered,
  ...props
}: TitleProps): ReactElement<TitleProps> => {
  const { children } = props;
  const titleClassNames: string = [
    styles.titleContainer,
    centered ? styles.centered : "",
  ].join(" ");
  return (
    <div className={titleClassNames}>
      <h2 className={styles.title}>{children}</h2>
    </div>
  );
};

Title.defaultProps = {
  centered: false,
};

export default Title;
