import { Card } from "antd";
import React, { ReactElement } from "react";
import { CatalogueBackground } from "../../../assets/images";
import {
  catalogueTitle,
  catalogueSubtitle,
  catalogueButton,
} from "../../../assets/texts/home";

import Button from "../../generalUI/Button";
import "./catalogue.scss";

const Catalogue = (): ReactElement => {
  const catalogueName = process.env.REACT_APP_CATALOGUE_NAME || "";
  return (
    <div className="catalogueGrid">
      <Card className="catalogueCard" hoverable>
        <h3 className="title">{catalogueTitle}</h3>
        <p>{catalogueSubtitle}</p>
        <Button
          color="blackButton"
          href={`https://ditec-rac.s3.us-west-2.amazonaws.com/assets/${catalogueName}.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          {catalogueButton}
        </Button>
      </Card>
      <a href="./fleet" className="catalogueImage">
        <img src={CatalogueBackground} alt="Modelos" />
      </a>
    </div>
  );
};

export default Catalogue;
