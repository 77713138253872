import {
  BankOutlined,
  BarcodeOutlined,
  BookOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CreditCardOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import React, { ReactElement } from "react";
import Button from "../../generalUI/Button";
import styles from "./voucherCard.module.scss";
import * as texts from "../../../assets/texts/voucher";
import priceFormat from "../../../utils/formats/priceFormat";
import { ReservationResult } from "../../../services/outwardTypes/rent";

interface Props {
  data: ReservationResult | null;
  backToHome: () => void;
  tryAgain: () => void;
}

const VoucherCard = (props: Props): ReactElement => {
  const { data, backToHome, tryAgain } = props;
  const success = data?.statusCode === 0;
  const icon = success ? (
    <CheckCircleOutlined className={styles.successIcon} />
  ) : (
    <CloseCircleOutlined className={styles.failureIcon} />
  );
  const title = success ? (
    <p className={styles.successTitle}>{texts.successTitle}</p>
  ) : (
    <p className={styles.failureTitle}> {texts.failureTitle}</p>
  );
  const message = success ? texts.successMessage : texts.failureMessage;
  return (
    <Card hoverable className={styles.voucherCard} bodyStyle={{ padding: "0" }}>
      <Row className={styles.iconRow}>
        <Col span={24} className={styles.iconContainer}>
          {icon}
        </Col>
        <Col span={24}>{title}</Col>
      </Row>
      {data && success && (
        <Row className={styles.infoRow}>
          <Row justify="space-around" className={styles.dataRow}>
            <Col span={2} className={styles.icon}>
              <BookOutlined />
            </Col>
            <Col span={10} className={styles.label}>
              {texts.reservationLabel}
            </Col>
            <Col span={12} className={styles.value}>
              #{data.reservation}
            </Col>
          </Row>
          <Row justify="space-around" className={styles.dataRow}>
            <Col span={2} className={styles.icon}>
              <DollarOutlined />
            </Col>
            <Col span={10} className={styles.label}>
              {texts.amountLabel}
            </Col>
            <Col span={12} className={styles.value}>
              {priceFormat(data.amount)}
            </Col>
          </Row>
          <Row justify="space-around" className={styles.dataRow}>
            <Col span={2} className={styles.icon}>
              <CreditCardOutlined />
            </Col>
            <Col span={10} className={styles.label}>
              {texts.cardLabel}
            </Col>
            <Col span={12} className={styles.value}>
              XXXX XXXX XXXX {data.cardNumber}
            </Col>
          </Row>
          <Row className={styles.dataRow}>
            <Col span={2} className={styles.icon}>
              <BankOutlined />
            </Col>
            <Col span={10} className={styles.label}>
              {texts.paymentLabel}
            </Col>
            <Col span={12} className={styles.value}>
              {data.paymentType}
            </Col>
          </Row>
          <Row className={styles.dataRow}>
            <Col span={2} className={styles.icon}>
              <BarcodeOutlined />
            </Col>
            <Col span={10} className={styles.label}>
              {texts.transactionLabel}
            </Col>
            <Col span={12} className={styles.value}>
              {data.status}
            </Col>
          </Row>
        </Row>
      )}
      <Row className={styles.infoRow}>
        <Col span={24}>{message}</Col>
      </Row>
      <Row className={styles.buttonRow}>
        <Col span={success ? 24 : 12}>
          <Button
            color={success ? "goldButton" : "blackButton"}
            onClick={backToHome}
          >
            {texts.homeButton}
          </Button>
        </Col>
        {!success && (
          <Col span={12}>
            <Button color="blackButton" onClick={tryAgain}>
              {texts.tryAgainButton}
            </Button>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default VoucherCard;
